@import '../../assets/scss/global';

.user-header-options {
  padding: 8px;
  .dropdown-toggle {
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    width: 32px;
    height: 32px;
    background-color: $branding-sdc-400;
    color: $grey-800;
    line-height: 29px !important;
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    z-index: 2;
    right: 0;
    left: auto;
    padding: 0;
    border-radius: 0;
    background: none;
    border: none;
    min-width: 218px;
    background-color: $branding-sdc-500;
    .dropdown-divider {
      margin: 0;
    }
    .role {
      @include body-small;
      color: $branding-sdc-300;
    }

    a.dropdown-section,
    a.dropdown-section:visited,
    a.dropdown-section:active,
    a.dropdown-section:hover {
      background-color: $branding-sdc-500 !important;
      cursor: default !important;
    }

    .dropdown-item {
      @include body-regular;
      color: white !important;
      background-color: $branding-sdc-500;
      padding: 14px 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      &.active {
        background-color: $branding-sdc-400 !important;
      }
      &:hover {
        background-color: $branding-sdc-400 !important;
      }
      &:focus {
        background-color: $branding-sdc-300 !important;
      }
    }
  }
}
