@import '../../assets/scss/typography';

.file-information-entry {
  padding: 0 16px;

  .key {
    @include body-small-bold;
    color: $grey-800;
    padding: 16px 0 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .value {
    @include body-regular;
    color: $grey-800;
    border-bottom: 1px dashed #e2e8f0;
    padding: 0 0 8px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
