@import '/src/assets/scss/main';

.subscription-page-container {
  height: 100%;

  .section-header {
    line-height: 27px;
    padding: 32px 0 10px 0;
    @include heading-title-20-bold;
  }
  .usage-container {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
  }
}
