@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.status-card {
  .timeout-card {
    height: 64px;
    display: grid;
    grid-template-columns: 40px 250px auto;
    padding: 12px 16px;
    user-select: none;

    color: $grey-800;
    background-color: $warning-100;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid $warning-400;

    @include body-regular;
    div {
      display: flex;
      align-items: center;
      flex: 1;
    }
    .card-body {
      .title {
        width: 250px;
      }
      .subtitle {
        color: $info-500;
      }
    }
    .message {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: $warning-600;
      @include body-bold;

      svg path {
        fill: $warning-600;
      }
    }
  }
}
