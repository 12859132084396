@import '/src/assets/scss/typography';

/* Override bootstrap style*/
.pagination-dropdown .btn,
.pagination-dropdown-active .btn {
  height: 48px;
  border-radius: 4px;
  @include body-regular;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  margin-left: 8px;
  padding: 0px 10px;
  border: 1px solid $grey-400;
  color: $grey-800;
  background-color: transparent;
  margin-top: 0px;

  // Small size
  &.small {
    height: 32px;
    @include body-small;
    margin-left: 6px;
  }
}

.pagination-dropdown-dark {
  color: $white !important;
}

.pagination-dropdown,
.pagination-dropdown-active {
  .btn {
    &:hover,
    &:active,
    &:focus,
    &:visited,
    &:focus-visible,
    &:focus-within,
    &:target,
    &:active:hover {
      color: $white;
      background-color: $branding-sdc-400 !important;
      border: 1px solid $branding-sdc-400 !important;
    }
  }
}

.pagination-dropdown-active .btn {
  background-color: $branding-sdc-400 !important;
  border: 1px solid $branding-sdc-400 !important;
}

/* Align with other buttons */
.pagination-dropdown button,
.pagination-dropdown-active button {
  margin-top: -3px !important;
}

/* Prevent starting a new line */
.dropdown {
  display: inline;
}

.pagination-dropdown .dropdown-menu,
.pagination-dropdown .dropdown-menu a,
.pagination-dropdown-active .dropdown-menu,
.pagination-dropdown-active .dropdown-menu a {
  @include body-regular;
  text-decoration: none;
  color: $grey-800;
}

.pagination-dropdown .dropdown-menu {
  transform: translate3d(0, 27px, 0px) !important;
}
