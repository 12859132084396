@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.result-pending-tray-container {
  height: 100%;

  max-height: calc(100vh - 240px);

  &.pad-for-notification {
    max-height: calc(100vh - 272px);
  }

  .pagination {
    display: flex;
    align-items: flex-end;
  }

  .items-container {
    @include scroll-style-default;
    padding: 4px 0;
    overflow-y: auto;
    max-height: calc(100vh - 400px);

    &.pad-for-notification {
      max-height: calc(100vh - 432px);
    }
  }

  .result-description {
    @include body-regular;
    color: $info-500;
    padding: 8px 0;
  }

  .result-placeholder-container {
    padding-top: 10vh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    overflow-y: visible;

    img {
      width: 30vh;
    }
    text-align: center;

    .guide-content {
      @include heading-title-20;
      color: $branding-sdc-400;
    }
    .not-found-content {
      @extend .guide-content;
      @include heading-title-20-bold;
    }
    // Laptop screen
    @media screen and (max-height: calc($low-res-device-max-height + 100px)) {
      padding-top: 5vh;
      .not-found-content {
        @extend .guide-content;
        @include body-bold;
      }
    }
    // Low resolution screen
    @media screen and (max-height: $low-res-device-max-height) {
      display: none;
    }
  }
  .simple-result-placeholder-container {
    width: 100%;
    height: 100%;
    user-select: none;
    text-align: left;
    color: $grey-600;
    padding: 8px 0;
    @include body-italic;
    display: none;

    // Tablet resolution display
    @media screen and (max-height: $low-res-device-max-height) {
      display: block;
    }
  }

  .status-card:not(:first-child) {
    margin-top: 8px;
  }
}
