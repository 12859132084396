@import '../../assets/scss/global';

.task-bar-container.stick-top {
  top: 0;
}
.task-bar-container.stick-navbar {
  top: $header-height;
}

.task-bar-container.task-bar-large {
  height: 56px;
}
.task-bar-container.expand-left {
  padding-left: 116px;
}

.task-bar-container {
  display: flex !important;
  position: fixed;
  left: 0;
  right: 0;
  height: 48px;
  padding: 0 24px;
  gap: 10px;
  background: $branding-sdc-500;
  width: 100%;
  color: #fff;
  box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
    0px 4px 6px -1px rgba(15, 23, 42, 0.1),
    0px 2px 4px -1px rgba(15, 23, 42, 0.06);
  display: grid;
  align-items: center;
  user-select: none;

  &.sub-nav {
    background-color: $branding-sdc-400;
    svg path {
      fill: white;
    }
  }

  &.dark {
    background-color: $branding-sdc-700;
    svg path {
      fill: white;
    }
  }
  .prefix-content {
    white-space: nowrap;
    display: flex;
    width: 100%;
    align-items: center;

    .prefix {
      display: flex;
      align-items: center;
    }

    .dark {
      color: $branding-sdc-300;
    }
  }
  .action-area {
    align-self: center;
    justify-self: end;
  }

  .arrow-container {
    align-self: center;
    padding: 0 12px;
    width: 25px;
    height: 25px;
  }

  // Only show the document name when the screen is smaller
  // than regular tablet sizes
  @media screen and (max-width: 750px) {
    .prefix-content,
    .arrow-container {
      display: none;
    }
  }
}

.task-bar-container + div {
  padding-top: 48px;
}

.task-bar-container.task-bar-large + div {
  padding-top: 56px;
}
