@import '../../../assets/scss/typography';

.container-management-data-grid {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;

  .table-column {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    @include body-small-bold;

    span {
      max-width: 15rem;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .container-management-data-grid > .data-grid-wrapper {
    margin-bottom: 0;
  }
}
