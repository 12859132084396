@import '../../assets/scss/typography';

.edit-container-modal-container {
  .modal-body {
    @include heading-title-18;
    color: $grey-800;
    border-top: 1px solid #ebeefa;
    border-bottom: 1px solid #ebeefa;
    background-color: #fff;
    padding: 16px 16px 3rem 16px;
  }

  .modal-content {
    border: none;
  }

  .modal-header {
    padding: 16px;
    border: none;
    @include heading-title-20-bold;
    background-color: $branding-sdc-500;
    color: white;
  }

  .modal-footer {
    border: none;
  }

  .modal-dismiss-button {
    padding: 0;
  }

  .modal-title {
    @include heading-title-20-bold;
  }

  .modal-body {
    padding-bottom: 16px;
  }

  button.close-btn {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 1rem;
    background: none !important;
  }

  .btn {
    border-radius: 0.2rem !important;
  }

  button.sdc-link-button.standard {
    text-decoration: none;
  }

  button.custom-button.warning.default {
    color: $warning-900;
  }

  .close-button {
    position: absolute;
    right: 14px;
    top: 14px;
    background: none;
    border: none;
    cursor: pointer;
    svg {
      fill: white;
    }
  }
}
