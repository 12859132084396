@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.status-card {
  .processing-card {
    display: grid;
    grid-template-columns: 40px auto;
    padding: 12px 16px;
    user-select: none;

    color: $grey-800;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid $grey-400;

    @include body-regular;

    .icon {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .card-body {
      .title {
        width: 350px;
      }
      .subtitle {
        color: $info-500;
      }
    }
  }
}
