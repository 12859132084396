@import '../../assets/scss/main';

.stylesheet-page-container {
  margin-top: 65px;
  height: calc(100vh - 65px);
  overflow-y: scroll;
  padding: 1rem;
  h1 {
    padding: 1rem 0;
  }

  .typo-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background: white;
    border: 1px solid black;
    div {
      padding: 1rem;
      border-top: 1px solid black;
      display: flex;
      flex-direction: column;
      max-width: 50%;
      flex-basis: 100%;
    }
    &:first-child,
    &:last-child {
      border-top: none;
    }
  }

  .color-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background: white;
    .colorSet {
      align-items: center;
      padding: 1rem;
      gap: 16px;
      border-bottom: none;
      display: flex;
      max-width: 50%;
      flex-basis: 100%;
    }
    .sample-color {
      padding: 1rem;
      border: 1px solid black;
    }
  }
  .button-container {
    display: flex;
    flex-wrap: wrap;
    > * {
      padding: 1rem;
      max-width: 33%;
      flex-basis: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
