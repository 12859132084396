@import './variables';
@import './typography';

.custom-button {
  height: 48px;
  min-width: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid $grey-400;
  border-radius: 4px;
  padding: 8px 12px;
  box-shadow: none;
  background-color: $grey-100;
  color: $grey-800;
  white-space: nowrap;
  @include body-bold;
  line-height: 20px;
  svg path {
    fill: $grey-800;
  }

  .button-text {
    padding-top: 0.25rem;
  }

  &:hover {
    background-color: $grey-200;
    border-color: $grey-500;
  }
  &:active {
    border-color: $grey-200;
    background-color: $grey-200;
  }
  &:disabled {
    pointer-events: none;
    border-color: $grey-200;
    color: $grey-500;
    background-color: $grey-100;
    box-shadow: none;
    svg path {
      fill: $grey-500;
    }
  }

  &.small {
    height: 32px;
    min-width: 32px;
    @include body-bold;
    line-height: 20px;
    padding: 0 8px;
  }

  &.primary {
    box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14),
      0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2);
    background-color: $branding-sdc-500;
    border-color: $branding-sdc-500;
    color: $white;
    svg path {
      fill: $white;
    }
    &:hover {
      background-color: $branding-sdc-400;
      border-color: $branding-sdc-400;
    }
    &:active {
      border-color: $branding-sdc-300;
      background-color: $branding-sdc-300;
      box-shadow: none;
    }
    &:disabled {
      pointer-events: none;
      border-color: $grey-200;
      color: $grey-500;
      background-color: $grey-100;
      box-shadow: none;
      svg path {
        fill: $grey-500;
      }
    }
    &:focus {
      box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14),
        0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2);
      background-color: $branding-sdc-500;
      border-color: $branding-sdc-500;
      color: $white;
    }
  }

  &.secondary {
    box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14),
      0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2);
    background-color: $white;
    color: $branding-sdc-800;
    border-color: $grey-200;
    svg path {
      fill: $branding-sdc-800;
    }
    &:hover {
      background-color: $branding-sdc-100;
      border-color: $branding-sdc-100;
    }
    &:active {
      background-color: $branding-sdc-200;
      border-color: $branding-sdc-100;
      box-shadow: none;
    }
    &:disabled {
      pointer-events: none;
      border-color: $grey-200;
      color: $grey-500;
      background-color: $grey-100;
      box-shadow: none;
      svg path {
        fill: $grey-500;
      }
    }
    &:focus {
      box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14),
        0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2);
      background-color: $white;
      color: $branding-sdc-800;
      border-color: $grey-200;
    }
  }

  &.warning {
    box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14),
      0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2);
    background-color: $product-mustard-500;
    color: $warning-900;
    border-color: $product-mustard-500;
    svg path {
      fill: $warning-900;
    }
    &:hover {
      background-color: $product-mustard-600;
      border-color: $product-mustard-600;
    }
    &:active {
      background-color: $product-mustard-200;
      border-color: $product-mustard-100;
      box-shadow: none;
    }
    &:disabled {
      pointer-events: none;
      border-color: $grey-200;
      color: $grey-500;
      background-color: $grey-100;
      box-shadow: none;
      svg path {
        fill: $grey-500;
      }
    }
    &:focus {
      box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14),
        0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2);
      background-color: $product-mustard-500;
      color: $warning-900;
      border-color: $product-mustard-500;
    }
  }

  &.outlined {
    border: 1px solid;
    border-color: $grey-400;
    background-color: $white;
    color: $grey-800;
    box-shadow: none;
    svg path {
      fill: $grey-800;
    }
    &:hover {
      border-color: $branding-sdc-400;
      color: $branding-sdc-400;
      svg path {
        fill: $branding-sdc-400;
      }
    }
    &:active {
      border-color: $branding-sdc-300;
      color: $branding-sdc-300;
      svg path {
        fill: $branding-sdc-300;
      }
    }
    &:disabled {
      pointer-events: none;
      border-color: $grey-500;
      color: $grey-500;
      box-shadow: none;
      svg path {
        fill: $grey-500;
      }
    }
    &:focus {
      border: 1px solid;
      border-color: $grey-400;
      background-color: $white;
      color: $grey-800;
      box-shadow: none;
    }
  }

  &.outlined-dark {
    border: 1px solid;
    border-color: $white;
    background-color: transparent;
    color: $white;
    box-shadow: none;
    svg path {
      fill: $white;
    }
    &:hover {
      border-color: $white;
      color: $white;
      background-color: rgba(255, 255, 255, 0.2);
      svg path {
        fill: $white;
      }
    }
    &:active {
      border-color: $white;
      color: $white;
      background-color: rgba(255, 255, 255, 0.5);
      svg path {
        fill: $white;
      }
    }
    &:disabled {
      border: 1px solid;
      pointer-events: none;
      border-color: $grey-500;
      color: $grey-500;
      box-shadow: none;
      svg path {
        fill: $grey-500;
      }
    }
    &:focus {
      border: 1px solid;
      border-color: $white;
      background-color: transparent;
      color: $white;
      box-shadow: none;
    }
  }

  &.link {
    padding: 0 8px;
    height: auto;
    background-color: transparent;
    color: $info-500;
    border: none;
    box-shadow: none;
    text-decoration: underline;
    @include body-regular;
    &:hover {
      color: $info-400;
    }
    svg path {
      fill: $info-500;
    }

    &:disabled {
      pointer-events: none;
      color: $grey-500;
      svg path {
        fill: $grey-500;
      }
    }
  }

  &.link-secondary {
    padding: 0 8px;
    height: auto;
    background-color: transparent;
    color: $grey-800;
    border: none;
    box-shadow: none;
    text-decoration: underline;
    @include body-regular;
    &:hover {
      color: $info-400;
    }
  }

  &.text {
    padding: 0 8px;
    background-color: transparent;
    color: $info-500;
    border: none;
    box-shadow: none;
    @include body-bold;
    svg path {
      fill: $info-500;
    }
    &:hover {
      color: $grey-800;
      background-color: $grey-200;
      svg path {
        fill: $grey-800;
      }
    }
    &:active {
      color: $branding-sdc-800;
      background-color: $branding-sdc-200;
      svg path {
        fill: $branding-sdc-800;
      }
    }
    &:disabled {
      pointer-events: none;
      background-color: transparent;
      color: $grey-500;
      box-shadow: none;
      svg path {
        fill: $grey-500;
      }
    }
  }

  &:focus,
  &:active:focus {
    @extend :active;
  }

  &:hover,
  &:active,
  &:focus,
  &:visited,
  &:focus-visible,
  &:focus-within,
  &:target,
  &:active:hover {
    outline: none !important;
  }
}
