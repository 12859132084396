@import '../../assets/scss/global';

.menu-options {
  padding: 8px;
  .dropdown-toggle {
    @include body-bold;
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    margin: 8px;
    &::after {
      display: none;
    }
    path {
      fill: white;
      width: 20px;
    }
  }
  a.dropdown-section,
  a.dropdown-section:visited,
  a.dropdown-section:active,
  a.dropdown-section:hover {
    background-color: $branding-sdc-600 !important;
    cursor: default !important;
  }
  .dropdown-menu {
    max-height: 50vh;
    z-index: 2;
    left: auto;
    right: 0;
    padding: 0;
    border-radius: 0;
    background: none;
    min-width: 218px;
    border: none;
    background-color: $branding-sdc-500;
    .dropdown-divider {
      margin: 0;
    }
    .role {
      @include body-small;
      color: $branding-sdc-300;
    }
    .dropdown-item {
      @include body-regular;
      color: white;
      background-color: $branding-sdc-500;
      padding: 14px 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        width: 20px;
      }

      &.dropdown-section {
        background-color: $branding-sdc-600;
        cursor: default;
      }
      &.active {
        @include body-bold;
        background-color: $branding-sdc-400 !important;
      }
      &:hover {
        @include body-bold;
        background-color: $branding-sdc-400 !important;
      }
      &:focus {
        @include body-bold;
        background-color: $branding-sdc-300 !important;
      }
      &:first-child {
        @include body-bold;
        color: white;
        background-color: $branding-sdc-600;
      }
    }
  }
}
