@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.result-pending-tray-container {
  height: 100%;

  max-height: calc(100vh - 240px);

  &.pad-for-notification {
    max-height: calc(100vh - 272px);
  }

  .pagination {
    display: flex;
    align-items: flex-end;
  }

  .result-description {
    @include body-regular;
    color: $info-500;
    padding: 8px 0;
  }

  .result-placeholder-container {
    padding-top: 10vh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    overflow-y: visible;

    img {
      width: 30vh;
    }
    text-align: center;

    .guide-content {
      padding-top: 1rem;
      @include heading-title-20-bold;
      color: $grey-600;
    }
    .not-found-content {
      padding-top: 1rem;
      color: $branding-sdc-400;
      @include heading-title-20-bold;
    }
    // Laptop screen
    @media screen and (max-height: calc($low-res-device-max-height + 100px)) {
      padding-top: 5vh;
      .not-found-content,
      .guide-content {
        @include body-bold;
      }
    }
    // Low resolution screen
    @media screen and (max-height: $low-res-device-max-height) {
      display: none;
    }
  }
  .simple-result-placeholder-container {
    width: 100%;
    height: 100%;
    user-select: none;
    text-align: left;
    color: $grey-600;
    padding: 8px 0;
    @include body-italic;
    display: none;

    // Tablet resolution display
    @media screen and (max-height: $low-res-device-max-height) {
      display: block;
    }
  }

  .status-card:not(:first-child) {
    margin-top: 8px;
  }

  .dropzone-container {
    display: flex;
    height: calc(100vh - 410px);

    .dropzone {
      position: relative;
      flex: 1;
      overflow-y: auto;
      background: $white;
      box-sizing: border-box;
      border-radius: 8px;
      outline: none;
      cursor: default;
      padding: 4px 0;
      @include scroll-style-default;

      &.drag-over {
        border-color: $white;
        background-image: linear-gradient(
            to right,
            $info-500 70%,
            transparent 30%
          ),
          linear-gradient(to right, $info-500 70%, transparent 30%),
          linear-gradient(to bottom, $info-500 70%, transparent 30%),
          linear-gradient(to bottom, $info-500 70%, transparent 30%);
        background-position: left top, left bottom, left top, right top;
        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
        background-size: 20px 3px, 20px 3px, 3px 20px, 3px 20px;
      }
    }
  }
}
