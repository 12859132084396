@import '../../assets/scss/typography';

.document-modal {
  text-align: left;
  overflow: hidden;
  position: fixed;
  display: block;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow-x: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
}

.visible {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
  z-index: 9999;
}

.modal-wrapper {
  border-radius: 0;
  background: $branding-sdc-900;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100vw;
  height: 100%;
  min-height: 400px;
  min-width: 400px;
  margin: 0 auto;
  background-color: $branding-sdc-900;
  align-self: center;
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  transform: scale(0);
}

.visible .modal-wrapper {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

button.close-btn {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem;
  background: none !important;
}
