.table-metadata-section-component {
  padding-bottom: 16px;
  .container {
    display: grid;
    grid-template-columns: auto;
    gap: 8px;
    button {
      justify-content: start;
    }
  }
}
