@import '../../assets/scss/typography';

.restricted-area-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .main-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
  }

  img {
    width: fit-content;
  }
  text-align: center;

  .forbidden-content {
    line-height: 2.5;
    .title {
      @include heading-title-20;
      color: $warning-600;
    }
    .subtitle {
      @include body-regular;
      color: $grey-800;
    }
  }
}
