@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.help-side-panel {
  display: flex;
  flex-direction: column;
  width: 360px;
  position: relative;
  padding: 0;
  border-left: 1px solid $grey-400;
  background: $grey-100;
  overflow: hidden;
  // white-space: nowrap;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
  &.show {
    width: 23rem;
    box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
      -4px 0px 6px -1px rgba(15, 23, 42, 0.1),
      -2px 0px 4px -1px rgba(15, 23, 42, 0.06);
  }
  &.hide {
    width: 0;
  }

  .title {
    @include body-large-bold;
    color: $branding-sdc-800;
    text-align: center;
  }

  .header {
    padding: 0 16px;
    display: grid;
    height: 40px;
    grid-template-columns: auto auto;
    align-items: center;
    border-bottom: 1px solid $grey-400;
    div:last-child {
      display: flex;
      flex-direction: row-reverse;
    }

    @include body-bold;
    color: $grey-800;
  }

  .content {
    padding: 16px;
    img {
      width: 100%;
      padding: 2rem 0;
    }
  }

  button {
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
  .frame {
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
  }
  .box {
    position: relative;
    top: 0;
    left: 0;
  }
  .arrow {
    position: absolute;
    top: 5px;
    left: 4px;
  }

  // Tablet resolution display
  @media screen and (max-width: $tablet-device-max-width) {
    position: fixed;
    right: 0;
    height: 100%;
  }
}
