@import '../../assets/scss/typography';

.pending-tray-side-panel {
  .description-label {
    @include body-regular;
    padding: 0 16px 8px 16px;
    color: $grey-800;
    user-select: none;
    white-space: nowrap;
    // Positioning tool tip
    img {
      padding-left: 6px;
      padding-bottom: 2px;
    }
  }

  .tag-section {
    padding: 0 16px 16px 16px;
  }

  .metadata-input {
    padding: 0 16px;
    .metadata-key {
      @include body-small-bold;
      color: $grey-800;
      padding: 16px 0 0 0;
    }
  }

  .error-box {
    padding: 16px;
    .error-body {
      @include body-small;
      padding: 16px;
      background: $danger-100;
      color: $danger-500;
      box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
        0px 4px 6px -1px rgba(15, 23, 42, 0.1),
        0px 2px 4px -1px rgba(15, 23, 42, 0.06);
      border-radius: 4px;
    }

    .title {
      @include body-small-bold;
    }
  }

  .loader {
    padding-top: 2rem;
    .loader-set {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 8px;
      padding: 4px;
    }
    .text {
      display: flex;
      justify-content: center;
      padding-bottom: 8px;
      @include body-regular;
      color: $grey-800;
    }
  }

  // Since the capture page has a fixed buttom bar, we need to customize the table modal
  .full-size-modal-component {
    bottom: 80px;

    &.expanded {
      top: 0;
      height: calc(100%);
    }
  }
}
