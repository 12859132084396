@import '../../assets/scss/typography';

.document-protection-data-grid {
  flex: 1;
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .table-column {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    @include body-small-bold;

    span {
      max-width: 15rem;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .column-icon {
      height: 16px;
    }
  }

  .data-col {
    .radio-dropdown-container > .radio-dropdown > button {
      padding-right: 0;
    }
  }

  .action-dropdown-container {
    padding-bottom: 8px;
    width: fit-content;
  }

  .document-protection-data-grid > .data-grid-wrapper {
    margin-bottom: 0;
  }

  .data-row-container {
    // Make the table body scroll
    height: calc(100vh - 382px);
    min-height: 40vh;
  }
}
