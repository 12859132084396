@import '../../assets/scss/typography';

.code-footer {
  z-index: 3;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  color: $white;
  height: $code-footer-height;
  background: $white;
  display: flex;
  justify-content: center;
  padding: 18px;
  align-content: space-between;
  flex-flow: column wrap;
  box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
    0px -4px 6px -1px rgba(15, 23, 42, 0.1),
    0px -2px 4px -1px rgba(15, 23, 42, 0.06);

  .code-footer-description {
    @include body-regular;
    color: $grey-700;
  }
}
