@import '../../assets/scss/typography';

.document-readonly-side-panel {
  width: 100%;
  .loader {
    .loader-set {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 8px;
      padding: 4px;
    }
    .text {
      display: flex;
      justify-content: center;
      padding-bottom: 8px;
      @include body-regular;
      color: $grey-800;
    }
  }
}
