@import '../../assets/scss/typography';

.upload-dropzone {
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  padding: 34px;
  flex-direction: column;
  cursor: default;
  &.list {
    padding: 24px 34px;
  }
  .dropzone-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    > * {
      margin-bottom: 30px;
    }
    svg {
      width: 253px;
    }
    .description {
      color: $grey-600;
      @include heading-title-20-bold;
      &.focused {
        color: $info-500;
        @include heading-title-32-bold;
      }
    }
  }
}
