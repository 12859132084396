@import '../../assets/scss/typography';

.cheer-icon-container {
  .frame {
    top: 0;
    left: 0;
  }
  .text {
    padding-top: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 40px;
    color: $success-500;
    @include heading-title-32-bold;
  }
}
