$primary: #005d83;
$font-family: OpenSans,
  // Safari for OS X and iOS
  -apple-system,
  // Chrome < 56 for OS X
  BlinkMacSystemFont,
  // Windows
  'Segoe UI',
  // Android
  'Roboto',
  // Basic web fallback
  'Helvetica Neue',
  Helvetica Arial, sans-serif;

$header-height: 56px;
$modal-footer-height: 80px;
$code-footer-height: 84px;

// Based on hi-res tablet like iPad Air
$tablet-device-max-width: 820px;
$low-res-device-max-height: 720px;

$icon-foreground-color-active: #475569;
$backdrop: #0f172a;

$default-font-size: 14px;
$small-font-size: 12px;

$black: #0d1423;
$white: #fff;
$grey-100: #f5f7fb;
$grey-200: #eff3fa;
$grey-300: #e3eaf7;
$grey-400: #d3ddee;
$grey-500: #b2bfd2;
$grey-600: #8b98ad;
$grey-700: #656e85;
$grey-800: #42495e;
$grey-900: #1c2136;

$success-100: #eafde8;
$success-200: #b8f9b9;
$success-300: #76ec82;
$success-400: #51d96d;
$success-500: #20c151;
$success-600: #17a550;
$success-700: #108a4d;
$success-800: #0a6f47;
$success-900: #065c42;

$danger-100: #fde7e7;
$danger-200: #f3caca;
$danger-300: #eaa4a4;
$danger-400: #da6060;
$danger-500: #c91b1b;
$danger-600: #b80000;
$danger-700: #a60000;
$danger-800: #860000;
$danger-900: #5b0000;

$info-100: #f0f8fd;
$info-200: #d4eafc;
$info-300: #a0cdfa;
$info-400: #5c9cef;
$info-500: #1f6beb;
$info-600: #1b55d6;
$info-700: #153eb9;
$info-800: #112a9c;
$info-900: #0c1a7e;

$warning-100: #fbf6dc;
$warning-200: #faedb7;
$warning-300: #f6dd8a;
$warning-400: #f0c346;
$warning-500: #eea909;
$warning-600: #d18500;
$warning-700: #b56800;
$warning-800: #984e00;
$warning-900: #753500;

$branding-sdc-100: #f2f6f8;
$branding-sdc-200: #c2ecf0;
$branding-sdc-300: #5ac3d6;
$branding-sdc-400: #2d96b4;
$branding-sdc-500: #005d83;
$branding-sdc-600: #004870;
$branding-sdc-700: #00365e;
$branding-sdc-800: #00264b;
$branding-sdc-900: #001b3e;

$product-mustard-100: #fdfaf4;
$product-mustard-200: #fff1da;
$product-mustard-300: #fee3b5;
$product-mustard-400: #f7c36b;
$product-mustard-500: #eea320;
$product-mustard-600: #d7931d;
$product-mustard-700: #c1831a;
$product-mustard-800: #aa7416;
$product-mustard-900: #936413;

$product-purple-100: #faf8fd;
$product-purple-200: #dfd4ed;
$product-purple-300: #c5b3de;
$product-purple-400: #af99ce;
$product-purple-500: #9e85bf;
$product-purple-600: #917baf;
$product-purple-700: #8470a0;
$product-purple-800: #776590;
$product-purple-900: #67577c;

$product-salmon-100: #fff5f4;
$product-salmon-200: #fad7d2;
$product-salmon-300: #f3b9b0;
$product-salmon-400: #ee9d91;
$product-salmon-500: #e88273;
$product-salmon-600: #da7a6c;
$product-salmon-700: #c97063;
$product-salmon-800: #b7675b;
$product-salmon-900: #a65e53;

$product-orange-100: #fff9f6;
$product-orange-200: #f6d5c5;
$product-orange-300: #f2b79b;
$product-orange-400: #eda17d;
$product-orange-500: #e86e34;
$product-orange-600: #d96731;
$product-orange-700: #cb602d;
$product-orange-800: #bc592a;
$product-orange-900: #ad5125;
