@import '/src/assets/scss/typography';

.filter-operator-selector-container {
  min-width: 300px;
  text-align: left;
  list-style: none;
  padding: 0;

  .option-item-container {
    min-height: 40px;
    border-top: 1px solid $grey-200;
    padding: 8px 16px;
    width: 100%;
    .option-input-container {
      padding: 8px 0 0 24px;
    }
  }

  .submit-filter-container {
    border-top: 1px solid $grey-200;
    padding: 16px;
    width: 100%;
    button {
      width: 100%;
    }
  }
}
