@import '../../assets/scss/typography';

.upload-header {
  display: flex;
  justify-content: space-between;

  .upload-title-group {
    padding: 24px 0 16px 0;
  }

  .upload-title {
    display: flex;
    @include heading-title-20-bold;
  }

  .upload-description {
    color: $grey-700;
    @include body-regular;
  }

  .upload-file-view-control {
    display: flex;
    align-self: flex-end;
    gap: 16px;
    padding-bottom: 23px;
    button.icon-wrapper {
      padding: 0;
      align-self: flex-end;
    }
    button.delete-button {
      position: relative;
      top: 7px;
    }
    div.vertical-bar {
      padding-top: 10px;
    }
    label.sdc-checkbox {
      top: 7px;
    }
  }
}
