@import '/src/assets/scss/typography';

.document-file-previewer-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  @include body-small;
  color: $grey-800;
  padding: 16px;

  .previewer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    img {
      filter: drop-shadow(0px 0px 2px rgba(15, 23, 42, 0.14))
        drop-shadow(0px 2px 2px rgba(15, 23, 42, 0.12))
        drop-shadow(0px 1px 3px rgba(15, 23, 42, 0.2));
      border: 1px solid $grey-400;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
