@import '/src/assets/scss/typography';
@import '/src/assets/scss/common';

/* Override bootstrap style*/

.sdc-dropdown {
  .dropdown-menu.show {
    min-width: 100%;
    transform: translate3d(0, 27px, 0px) !important;
  }

  a.dropdown-item.disabled {
    pointer-events: none;
    color: $grey-500;
  }
  .dropdown-item:hover,
  .dropdown-item:active {
    color: white;
    background-color: $info-500;
  }

  .dropdown-toggle {
    &.error {
      border: 1px solid $danger-500;
      color: $danger-500;
    }
  }
  // All BS dropdown will never overflow the page
  .dropdown-menu {
    max-height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll-style-default;
  }

  // Floating label nudges
  .label-text {
    @include body-small;
    color: $grey-600;
    position: absolute;
    left: 12px;
    top: -8px;
    user-select: none;
  }
  .button-with-label {
    padding-top: 12px;
  }
  .text-with-label {
    line-height: 2.5;
  }
}

.sdc-dropdown.light button {
  font-family: $font-family;
  @include body-regular;
  height: 48px;
  border-radius: 4px;
  text-align: left;
  margin: 0;
  border: 1px solid $grey-400;
  color: $grey-800;
  background-color: $white;

  &.no-outline {
    border: none !important;
  }

  // Small size
  &.small {
    @include body-regular;
    height: 32px;
    margin-left: 6px;
  }
}

.sdc-dropdown.dark button {
  font-family: $font-family;
  height: 48px;
  border-radius: 4px;
  @include body-regular;
  text-align: left;
  border: 1px solid white;
  color: $white;
  background-color: transparent;

  &.no-outline {
    border: none !important;
  }

  // Small size
  &.small {
    height: 32px;
    @include body-regular;
    margin-left: 6px;
  }
}

.sdc-dropdown.light {
  .btn,
  button {
    &:hover,
    &:active,
    &:focus,
    &:visited,
    &:focus-visible,
    &:focus-within,
    &:target,
    &:active:hover {
      background-color: white !important;
    }
  }
}

.sdc-dropdown.dark {
  .btn,
  button {
    &:hover,
    &:visited,
    &:focus-visible,
    &:focus-within,
    &:target {
      background-color: rgba(255, 255, 255, 0.2);
      color: $white !important;
      border: 1px solid $white !important;
    }

    &:active,
    &:focus,
    &:active:hover {
      background-color: rgba(255, 255, 255, 0.5);
      color: $white !important;
      border: 1px solid $white !important;
    }
  }
}

/* Prevent starting a new line */
.dropdown {
  display: inline;
}

.sdc-dropdown .dropdown-menu,
.sdc-dropdown .dropdown-menu a {
  user-select: none;
  @include body-regular;
  text-decoration: none;
  color: $grey-800;

  &:active,
  &:focus {
    color: white !important;
  }
}

.sdc-dropdown .default.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
  margin-right: 6px;
}

.sdc-dropdown .small.dropdown-toggle::after {
  float: right;
  margin-top: 8px;
  margin-right: 0px;
}

.validation-error-message {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $danger-500;
  margin-top: 5px;
  @include body-small-italic;
  svg,
  svg path {
    height: 13px;
    width: 13px;
  }
}
