.tag-container {
  .tag-input-form {
    display: block;
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .tag-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    div {
      margin-right: 2px;
      margin-top: 4px;
    }
  }
}
