@import '/src/assets/scss/global';

/*
* The tutorial from
* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_checkbox
*/
/* The sdc-checkbox */
.sdc-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  cursor: pointer;
  @include body-regular;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.disabled {
    cursor: default;
    color: $grey-500;
  }

  /* Large size of checkbox */
  &.large {
    line-height: 32px;
    height: 32px;
    padding-left: 44px;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    width: 20px;
    transform: translate(0, -50%);
    border-radius: 4px;
    border: 1px solid $grey-400;
    background-color: white;

    /* Large size of checkbox */
    &.large {
      margin-top: 0;
      height: 32px;
      width: 32px;

      /* Style the checkmark/indicator Large*/
      &:after {
        left: 9px;
        top: 3px;
        width: 10px;
        height: 18px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    /* Style the checkmark/indicator default*/
    &:after {
      left: 6px;
      top: 1px;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.disabled {
      cursor: default;
      background-color: $grey-200;
    }
  }
}

/* On mouse-over, add a grey background color */
.sdc-checkbox:hover input ~ .checkmark {
  background-color: white;

  &.disabled {
    cursor: default;
    background-color: $grey-200;
  }
}

/* When the checkbox is checked, add a blue background */
.sdc-checkbox input:checked ~ .checkmark {
  transition-duration: 0.7s;
  transition: opacity 1;
  background-color: $branding-sdc-500;

  &.disabled {
    cursor: default;
    background-color: $grey-500;
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.sdc-checkbox .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.sdc-checkbox input:checked ~ .checkmark:after {
  display: block;
}
