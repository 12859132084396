@import '../../assets/scss/global';

.upload-document-box-container {
  width: 100%;
  max-width: 150px;
  padding: 10px 0;

  button.upload-document-box {
    cursor: pointer;
    width: 100%;
    min-height: 204px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.5rem;
    border-radius: 4px;
    background-color: $info-100;
    border: 1px solid $info-400;
    box-shadow: none !important;
    outline: none !important;

    .title {
      color: #3b82f6;
      text-decoration: underline;
      @include body-large-bold;
    }

    input[type='file'] {
      display: none;
    }
  }

  &.list {
    max-width: 100%;
    button.upload-document-box {
      flex-direction: row;
      min-height: auto;
      padding: 1px 14px;
      justify-content: flex-start;
      svg {
        width: 26.67px;
      }
    }
    .constant {
      margin: 0;
    }
  }

  .constant {
    margin-top: 10px;
    color: $grey-700;
    word-wrap: break-word;
    @include body-large;
  }
}
