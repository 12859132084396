@import '../../assets/scss/typography';

.document-title-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 64px;
  background: $branding-sdc-700;
  padding: 0px 20px;
  z-index: 2;
  width: 100%;
  color: #fff;
  box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
    0px 4px 6px -1px rgba(166, 178, 206, 0.1),
    0px 2px 4px -1px rgba(15, 23, 42, 0.06);
  display: grid;
  // prefix | triangle | document name | close icon
  grid-template-columns: 5rem 20px auto auto;
  user-select: none;

  .prefix-content {
    align-self: center;
    color: $branding-sdc-300;
  }
  .separator {
    margin: 0 4px;
  }
  .detail {
    align-self: center;
  }
  .document-name {
    @include font-bold;
  }
  .arrow-right {
    align-self: center;
    width: 0;
    height: 0;
    border-top: 0.28rem solid transparent;
    border-bottom: 0.28rem solid transparent;
    border-left: 0.28rem solid $branding-sdc-300;
  }
  .arrow-container {
    padding: 29px 4px 0 0;
  }
  .close-button {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0px;
    margin: 0.7rem;
    background: none !important;
  }
}

.document-title-bar-container + div {
  padding-top: 64px;
}
