@import '../../assets/scss/typography';

.data-table-cell-component {
  .cell-container {
    position: relative;

    .action-button {
      position: absolute;
      right: 0;
      top: 6px;
      @include body-small;
      text-decoration: none;
      svg path {
        fill: $grey-800;
      }
    }
  }

  .close-icon {
    padding-right: 8px;
    top: 4px !important;
  }

  input[type='text'] {
    line-height: 2.5;
    border: 1px solid transparent;
    background: transparent;
    margin: 0;
    outline: none;
    // Make the text field fit the cell
    width: 100%;
    // A place for the done button
    padding-right: 42px;
    padding-left: 8px;
  }

  .error {
    border: 1px solid $danger-500 !important;
    background: $danger-100 !important;
  }

  input[type='text']:focus {
    border: 1px solid $info-500;
    background: transparent;
    @include body-small-bold;
  }

  input[type='text']:disabled {
    background: $grey-100;
  }
}
