@import '../../assets/scss/main.scss';

.hide-file-input {
  display: none;
}

.pending-tray-container {
  background-color: $info-100;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 56px;

  .button-group {
    display: flex;
    gap: 10px;
  }

  .view-container {
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .main-content {
    position: relative;
    background: $white;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
  }

  .result {
    height: 100%;
    &.archived-list {
      margin-bottom: 100px;
    }
  }

  .result-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 24px;
  }

  .hide-file-input {
    display: none;
  }

  // Tablet resolution display
  @media screen and (max-width: $tablet-device-max-width) {
    .collapse-icon-container {
      top: 44px !important;
    }
  }
}
