@import '../../assets/scss/main';

.document-search-container {
  background-color: $info-100;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 56px;

  .document-search-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    .title {
      @include body-large;
      padding-left: 8px;
      padding-right: 24px;
    }
    .font-italic {
      font-style: italic;
    }
  }

  .main-content {
    height: 100%;
    position: relative;
    background: $white;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .result-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: none;
    padding: 32px;
    height: 100%;
  }

  // Tablet resolution display
  @media screen and (max-width: $tablet-device-max-width) {
    .collapse-icon-container {
      top: -3px !important;
    }
  }
}
