@import '../../assets/scss/global';

.pending-tray-list-item {
  display: inline-grid;
  align-items: center;
  grid-template-columns: 35px auto;
  grid-row: auto;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid $grey-400;
  background: $white;
  height: 72px;
  width: 100%;
  padding: 0 20px;
  margin-top: 8px;
  min-width: 540px;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @include body-regular;

  button {
    display: inline-grid;
    align-items: center;
    grid-template-columns: auto 200px;
    grid-row: auto;
    height: 64px;
    width: 100%;
    padding: 0;
    outline: none !important;
    box-shadow: none !important;
    border: none;
    background: none;
  }

  &:first-child {
    margin-top: 0 !important;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    min-height: 100% !important;
  }

  .item-body {
    text-align: left;
    color: $grey-800;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .details {
    color: $grey-800;
    text-align: right;
    float: right;
    color: $grey-700;
    .author-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .title {
    @include body-bold;
    padding: 0 0;
    width: 150px;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    text-align: left;
  }

  .document-type {
    background-color: $grey-200;
    color: $grey-800;
    border: 1px solid $grey-400;
    border-radius: 4px;
    width: fit-content;
    padding: 0 4px;
    text-transform: uppercase;
    @include body-small-bold;
    &.undefined {
      @include body-small-bold;
      border: 1px solid $warning-400;
      background-color: $warning-100;
      color: $warning-700;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $grey-100;
    border-color: $info-400;
  }

  &.selected {
    cursor: pointer;
    background-color: $info-100;
    border-color: $info-400;
  }
}
