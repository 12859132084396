@import '/src/assets/scss/typography';

.pagination-button {
  height: 48px;
  width: 48px;
  border-radius: 4px;
  @include body-bold;
  align-items: center;
  text-align: center;
  border: 1px solid $grey-400;
  color: $grey-800;
  background-color: transparent;
  margin-left: 8px;
  box-shadow: none;
  padding: 4px 10px;
  user-select: none;

  &.active,
  &:hover {
    color: $white;
    background-color: $branding-sdc-400;
    border: 1px solid $branding-sdc-400;
    transition-duration: 0.5s;
    svg path {
      fill: $white;
    }
  }

  &:hover,
  &:active,
  &:focus,
  &:visited,
  &:focus-visible,
  &:focus-within,
  &:target,
  &:active:hover {
    outline: none !important;
  }

  &:disabled {
    color: $grey-500;
    border: 1px solid $grey-100;
    background: $grey-100;
  }

  // Dark mode
  &.dark {
    color: $white;
  }

  &.dark:disabled {
    color: $grey-700;
    border: 1px solid $grey-700;
    background: transparent;
  }

  // Small size
  &.small {
    height: 32px;
    width: 32px;
    @include body-small-bold;
    margin-left: 6px;
    line-height: 1.75;
  }

  &.flexible-width {
    width: auto;
  }
}
