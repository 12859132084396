@import '../../assets/scss/main.scss';

.hide-file-input {
  display: none;
}

.pending-tray-capture-container {
  background-color: $info-100;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 153px;

  .capture-side-panel {
    height: auto;
  }

  .button-group {
    display: flex;
    gap: 10px;
  }

  .view-container {
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .main-content {
    position: relative;
    background: $white;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
  }

  .result {
    height: 100%;
    &.archived-list {
      margin-bottom: 100px;
    }
  }

  .result-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 24px;
  }

  .hide-file-input {
    display: none;
  }

  .collapse-icon-container.with-title-bar-and-task-bar {
    top: 155px !important;
  }

  .tab-container .tab-item.selected {
    color: #fff;
    background: $branding-sdc-400;
    svg path {
      fill: #fff;
    }
  }
  .tab-container .tab-item {
    color: $grey-600;
    border: 1px solid $branding-sdc-200;
    svg path {
      fill: $branding-sdc-200;
    }
    &:hover {
      color: #fff;
      background: $branding-sdc-400;
      svg path {
        fill: #fff;
      }
    }
  }

  // Custom height nested components
  .result-pending-tray-container {
    max-height: calc(100vh - 290px);
  }

  // Since the capture page has a fixed buttom bar, we need to customize the table modal
  .full-size-modal-component {
    bottom: 0 !important;

    &.expanded {
      top: 0 !important;
      height: 100% !important;
    }
  }

  // Tablet resolution display
  @media screen and (max-width: $tablet-device-max-width) {
    .collapse-icon-container {
      top: 44px !important;
    }
  }
}
