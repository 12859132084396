@import '../../assets/scss/typography';

.badge-container {
  @include body-bold;
  height: 24px;
  color: white;
  padding: 1px 8px;
  border-radius: 14px;
  user-select: none;
  text-transform: uppercase;
  text-align: center;
  min-width: 60px;
  margin: 0 4px;

  &.file-type {
    background-color: $grey-800;

    &.doc,
    &.docx,
    &.txt {
      background-color: #153eb9;
    }
    &.xlsx,
    &.xls,
    &.csv {
      background-color: #108a4d;
    }
    &.ppt,
    &.pptx {
      background-color: $danger-800;
    }
    &.jpg,
    &.jpeg,
    &.jfif,
    &.pjpeg,
    &.png,
    &.tiff,
    &.pip {
      background-color: #eea909;
      color: $grey-900;
    }
    &.pdf {
      background-color: #c91b1b;
    }
  }
}
