@import '../../../assets/scss/variables';
@import '../../../assets/scss/typography';

.app-header-w-side-menu {
  position: fixed !important;
  background-color: $primary;
  top: 0;
  left: 96px;
  right: 0;
  height: $header-height;
  z-index: 1;
  padding: 0;

  path {
    fill: white;
  }

  @media screen and (max-width: $tablet-device-max-width) {
    left: 0;
  }

  .main-navbar {
    > *,
    > * > * {
      height: 56px;
      display: flex;
      align-items: center;
    }
    .options-group {
      padding: 0;
      flex: 1;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .navbar-nav .nav-link {
    color: #fff;
    font-size: 1rem;

    &:hover {
      color: #fff;
    }
  }
  .nav-divider {
    padding: 20px 15px;
  }
  .nav-divider::after {
    content: '';
    border-right: 1px solid $grey-600;
    position: absolute;
    height: 25px;
    top: 0px;
    margin-top: 15px;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .dropdown-divider {
    margin: 0;
  }
  .dropdown-item {
    padding: 10px;
    color: #6a6c6f;
    @include body-small;

    &:hover,
    &:focus {
      background-color: #e9ecef !important;
      color: inherit;
    }
    svg {
      margin-right: 10px;
    }
    .initials-icon-container {
      display: inline-block;

      span {
        float: left;
      }
    }
    .users-details-container {
      display: inline-block;
      padding: 5px 5px 5px 10px;

      p {
        margin: 0;
      }
    }
  }
}
