@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.uploading-toast-container {
  &.toast {
    width: auto;
    min-width: 350px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 2;
    border: none;
  }
  .upload-sticky-header {
    width: 100%;
    display: inline-flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    > div:last-child {
      flex: 1;
    }
    img {
      align-self: flex-start;
    }
    .has-error {
      align-self: flex-end;
      color: $danger-500;
      line-height: 16px !important;
      @include body-small-italic;
      &.collapsed {
        color: white;
      }
    }

    .view-collapsed-button {
      color: $info-500;
      text-align: right;
      @include body-underline-bold;
      &.success {
        color: $success-500;
      }
    }
  }
  .accordion-header {
    &.success {
      button.accordion-button {
        color: $success-500;
        background-color: $success-100;
        svg path {
          fill: $success-500;
        }
      }
      button.accordion-button:not(.collapsed) {
        color: $white;
        background-color: $success-500;
        svg path {
          fill: $white;
        }
      }
    }
  }
  .accordion-button {
    width: 546px;
    height: 48px;
    color: $info-500;
    background-color: $info-100;
    @include body-bold;
  }
  .accordion-button::after {
    display: none;
  }
  .accordion-button:not(.collapsed) {
    height: 48px;
    width: 546px;
    color: $white;
    background-color: $info-500;
  }
  .accordion-body {
    width: 546px;
    max-height: 250px;
    height: 100%;
    overflow-y: auto;
    @include scroll-style-default;
  }
  .accordion-item {
    border-color: $info-100;
  }
  .close-dialog-btn {
    text-decoration: none;
    color: black;
    padding: 0;
    @include body-bold;
  }
}
