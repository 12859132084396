@import '/src/assets/scss/typography';
@import '/src/assets/scss/common';

.secondary-dropdown {
  /* Override bootstrap style*/
  .btn {
    height: 24px;
    padding: 0;
    border: none;
    color: $grey-800;
    background-color: transparent;
    @include body-regular;
  }

  /* Prevent starting a new line */
  .dropdown {
    display: inline;
  }

  .dropdown-toggle {
    color: $grey-800 !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .dropdown-item:hover,
  .dropdown-item:active {
    color: white;
    background-color: $info-500;
  }

  /* Styling the caret */
  .dropdown-toggle::after {
    color: $grey-800;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-bottom: 0;
    border-left: 4px solid transparent;
    vertical-align: 1px;
    padding-bottom: 2px;
    margin-left: 8px;
  }

  .info .dropdown-toggle::after {
    color: $info-500;
  }

  .dropdown-menu,
  .dropdown-menu a {
    @include body-regular;
    text-decoration: none;
    color: $grey-800;
  }

  // All BS dropdown will never overflow the page
  .dropdown-menu {
    max-height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll-style-default;
  }
}
