@import '/src/assets/scss/typography';

.filter-tag {
  @include body-small;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 0 8px;
  height: 24px;
  user-select: none;

  /* Info/100 */
  background: $info-100;
  /* Info/200 */
  border: 1px solid $info-200;
  border-radius: 4px;

  button {
    padding: 0 0 3px 0;

    background: transparent;
    outline: none !important;
    box-shadow: none;
    border: none;
    svg {
      width: 15px;
      height: 15px;
    }
  }
}
