@import '../../assets/scss/typography';

.edit-permissions-side-panel-component {
  overflow-x: hidden;
  height: fit-content;
  padding: 16px;

  .text-field-label:first-child {
    padding-top: 0;
  }
}
