@import '../../assets/scss/variables';

.container-management-page {
  position: relative;
  margin: 0;
  max-width: 100%;
  padding-top: 56px;
  background-color: white;
  height: 100vh;

  .page-title {
    span {
      margin-left: 8px;
    }
  }

  .container-management-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
  }

  .pagination-navigator {
    @media only screen and (max-width: $tablet-device-max-width) {
      width: calc(100vw - 6%);
    }
  }
}
