@import '../../assets/scss/main';

.trash-container {
  background-color: $info-100;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 56px;

  .trash-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    .title {
      @include body-large;
      padding-left: 8px;
      padding-right: 24px;
    }
    .font-italic {
      font-style: italic;
    }
  }

  .view-container {
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .main-content {
    flex-grow: 1;
    flex-shrink: 100;
    flex-basis: 100%;
    position: relative;
    background: $white;
    max-width: 100%;
    width: calc(100vw - 456px);
    display: flex;
    flex-direction: row;
  }

  .result-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
  }

  .result-description {
    @include body-regular;
    color: $info-500;
    padding: 8px 0 0 0;
  }

  // Tablet resolution display
  @media screen and (max-width: $tablet-device-max-width) {
    .collapse-icon-container {
      top: -3px !important;
    }
  }
}
