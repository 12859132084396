@import '../../assets/scss/typography';

.document-preview-navigator {
  border-radius: 4px;
  position: relative !important;
  padding: 8px 8px 8px 0px;
  background-color: $branding-sdc-900;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  color: $white;
  z-index: 1;
  width: fit-content;

  &.stick-bottom {
    bottom: 2rem !important;
  }

  .custom-button {
    display: inline;
  }

  .prev-next-btn-group {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translate(-50%);
    width: 100%;

    .page-info {
      background: $branding-sdc-900;
      border: 1px solid white;
      color: white;
      @include heading-title-20;
      padding: 4px 16px;
      user-select: none;
    }
  }

  button.previous-btn,
  button.next-btn {
    padding: 0;
    border-radius: 50%;
    border: 1px solid white;
    background: $branding-sdc-900;
    background-color: $branding-sdc-900;

    &:hover,
    &:active,
    &:focus,
    &:visited,
    &:focus-visible,
    &:focus-within,
    &:target,
    &:active:hover {
      opacity: 0.75;
    }

    &:disabled {
      background-color: $grey-700;
    }
  }

  .control-group-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  // Adjust paddings for smaller screen based on screen width
  @media screen and (max-width: $tablet-device-max-width) {
    .control-group-container {
      flex-direction: column;
    }
  }
}
