@import '/src/assets/scss/typography';

.search-filter-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  background-color: $grey-100;
  border: 1px solid $grey-400;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  min-width: 360px;

  .custom-button.primary {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .search-control-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
  }

  .filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px 4px;
    width: 100%;
    justify-items: left;
  }

  .search-box-container {
    width: 100%;
    position: relative;
    .text-field {
      padding-right: 180px;
    }
    .dropdown-container {
      position: absolute;
      top: 4px;
      right: 10px;
      border-left: 1px solid $grey-400;
      padding-left: 8px;
    }
    svg {
      path {
        fill: #2d96b4;
      }
    }
  }

  .condition-description {
    display: flex;
    flex-direction: row;
    .field {
      color: $grey-800;
      @include body-small-bold;
    }
    .operator {
      @include body-small;
      padding-left: 3px;
      padding-right: 3px;
      color: $info-500;
    }
    .value {
      display: inline-block;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $grey-800;
      @include body-small;
    }
  }

  .clear-filter-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 24px;
    border: none;
    background: transparent;
    svg {
      height: 12px;
      width: 12px;
      path {
        fill: $info-400;
      }
    }
  }
}
