@import '../../assets/scss/global';

.document-thumbnail-container {
  position: relative;
  padding: 10px;
  &:hover {
    background-color: $info-100;
  }
  &:active {
    background-color: $info-500;
    .document-name {
      color: $white !important;
    }
  }

  label.sdc-checkbox {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 10px;
    top: 10px;

    border-radius: 1px;
  }

  &.list {
    width: 100%;
    padding: 0;
    button.document-thumbnail-btn {
      display: flex;
      align-items: center;
      gap: 5px;
      max-width: 100%;
      width: 100%;
      background: transparent !important;
      padding: 14px;
      border-bottom: 1px solid $grey-400;
      border-radius: 0;
      .document-name {
        text-align: left;
        margin: 0;
        -webkit-line-clamp: 1;
      }
    }
    .cloud-uploading-icon {
      width: 24px;
      height: 18px;
    }

    .preview-img {
      display: none;
    }

    label.sdc-checkbox {
      position: sticky;
      width: 32px;
      height: 32px;
      left: 10px;
      top: 10px;

      border-radius: 1px;
    }
  }

  .queuing {
    @include body-italic;
  }

  button.document-thumbnail-btn {
    position: relative;
    max-width: 150px;
    background: transparent !important;
    padding: 0;

    .preview-img {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 144px;
      height: 202px;
      border-radius: 4px;
    }

    .file-type-badge {
      position: absolute;
      right: 6px;
      top: 170px;
      @include body-bold;
    }

    &.loading {
      pointer-events: none;
    }

    .loading {
      opacity: 0.25;
    }

    .document-name {
      margin-top: 10px;
      color: $grey-800;
      line-height: 19px;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include body-large;
    }

    .sample-document-loading-icon {
      opacity: 0.25;
    }

    .cloud-uploading-icon {
      opacity: 0.25;
      float: left;
      z-index: 1;
      -webkit-animation: flickerAnimation 1s infinite;
      -moz-animation: flickerAnimation 1s infinite;
      -o-animation: flickerAnimation 1s infinite;
      animation: flickerAnimation 1s infinite;
      @keyframes flickerAnimation {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @-o-keyframes flickerAnimation {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @-moz-keyframes flickerAnimation {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @-webkit-keyframes flickerAnimation {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  .uploading-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 78px;
  }

  .uploading-title {
    color: $grey-800;
    @include body-small;
  }

  .document-loader {
    position: absolute;
    margin: auto;
    width: 60%;
    top: 80px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 11px;
    color: $info-500;
    font-size: 11px;
    .progress {
      background-color: $white;
      border: 1px solid $info-500;
      border-radius: 2px;
      .progress-bar {
        border: 2px solid $white;
        background-color: $info-500;
      }
    }
    > * {
      margin-bottom: 4px;
    }
  }
}
