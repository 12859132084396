@import './variables';

@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Regular),
    url(../fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}
$default-font-size: 1rem;
$small-font-size: 12px;

.font-bold {
  font-weight: bold !important;
}

.heading-title-40 {
  font-size: 40px !important;
}

.heading-title-36 {
  font-size: 36px !important;
}

.heading-title-32 {
  font-size: 32px !important;
}

.heading-title-28 {
  font-size: 28px !important;
}

.heading-title-24 {
  font-size: 24px !important;
}

.heading-title-20 {
  font-size: 20px !important;
}

.heading-title-18 {
  font-size: 18px !important;
}

.body-large {
  font-size: 16px !important;
}
.body-large-bold {
  font-size: 16px !important;
  font-weight: bold;
}

.body-underline-bold {
  font-size: $default-font-size !important;
  font-weight: bold;
  text-decoration: underline;
}
.body-bold {
  font-size: $default-font-size !important;
  font-weight: bold;
}
.body-underline {
  font-size: $default-font-size !important;
  text-decoration: underline;
  font-weight: normal;
}
.body-regular {
  font-size: $default-font-size !important;
  font-weight: normal;
}
.body-italic {
  font-size: $default-font-size !important;
  font-style: italic;
  font-weight: normal;
}
.body-small-bold {
  font-size: $small-font-size !important;
  font-weight: bold;
}
.body-small-underline {
  font-size: $small-font-size !important;
  text-decoration: underline;
  font-weight: normal;
}
.body-small {
  font-size: $small-font-size !important;
  font-weight: normal;
}
.body-small-italic {
  font-size: $small-font-size !important;
  font-style: italic;
  font-weight: normal;
}

@mixin font-bold {
  font-weight: bold;
}

@mixin heading-title-40-bold {
  font-size: 40px !important;
  font-weight: bold;
}
@mixin heading-title-40 {
  font-size: 40px !important;
  font-weight: normal;
}
@mixin heading-title-36-bold {
  font-size: 36px !important;
  font-weight: bold;
}
@mixin heading-title-36 {
  font-size: 36px !important;
  font-weight: normal;
}
@mixin heading-title-32-bold {
  font-size: 32px !important;
  font-weight: bold;
}
@mixin heading-title-32 {
  font-size: 32px !important;
  font-weight: normal;
}
@mixin heading-title-28-bold {
  font-size: 28px !important;
  font-weight: bold;
}
@mixin heading-title-28 {
  font-size: 28px !important;
  font-weight: normal;
}
@mixin heading-title-24-bold {
  font-size: 24px !important;
  font-weight: bold;
}
@mixin heading-title-24 {
  font-size: 24px !important;
  font-weight: normal;
}
@mixin heading-title-20-bold {
  font-size: 20px !important;
  font-weight: bold;
}
@mixin heading-title-20 {
  font-size: 20px !important;
  font-weight: normal;
}
@mixin heading-title-18-bold {
  font-size: 18px !important;
  font-weight: bold;
}
@mixin heading-title-18 {
  font-size: 18px !important;
  font-weight: normal;
}
@mixin body-large {
  font-size: 16px !important;
  font-weight: normal;
}
@mixin body-large-bold {
  font-size: 16px !important;
  font-weight: bold;
}
@mixin body-regular {
  font-size: $default-font-size !important;
  font-weight: normal;
}
@mixin body-underline-bold {
  font-size: $default-font-size !important;
  font-weight: bold;
  text-decoration: underline;
}
@mixin body-bold {
  font-size: $default-font-size !important;
  font-weight: bold;
}
@mixin body-underline {
  font-size: $default-font-size !important;
  text-decoration: underline;
  font-weight: normal;
}
@mixin body-regular {
  font-size: $default-font-size !important;
  font-weight: normal;
}
@mixin body-italic {
  font-size: $default-font-size !important;
  font-style: italic;
  font-weight: normal;
}
@mixin body-small-bold {
  font-size: $small-font-size !important;
  font-weight: bold;
}
@mixin body-small-underline {
  font-size: $small-font-size !important;
  text-decoration: underline;
  font-weight: normal;
}
@mixin body-small {
  font-size: $small-font-size !important;
  font-weight: normal;
}
@mixin body-small-italic {
  font-size: $small-font-size !important;
  font-style: italic;
  font-weight: normal;
}
