@import '/src/assets/scss/typography';

.search-box-component-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  background-color: $grey-100;
  border: 1px solid $grey-400;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  min-width: 360px;

  .custom-button.primary {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .search-control-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
  }

  .text-field-container {
    width: 100%;
    position: relative;
    .text-field {
      padding-right: 180px;
    }
    .dropdown-container {
      position: absolute;
      top: 4px;
      right: 10px;
      border-left: 1px solid $grey-400;
      padding-left: 8px;
    }
    svg {
      path {
        fill: #2d96b4;
      }
    }
  }
}
