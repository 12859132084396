@import '../../assets/scss/typography';

.qr-code-previewer-container {
  width: 100%;
  background-color: white;
  border-color: gray;
  min-height: 300px;
  margin-bottom: 20px;

  .section-label {
    padding: 8px 16px 0 16px;
    height: 40px;
    width: 100%;
    @include body-bold;
    color: $branding-sdc-500;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    background: linear-gradient(
      180deg,
      #eff3fa 0%,
      rgba(239, 243, 250, 0) 100%
    );
  }

  .qr-code-printing {
    display: flex;
    justify-content: center;
    min-height: 160px;
  }

  .qr-code-description {
    display: flex;
    justify-content: center;
    font-family: monospace;
    background: white;
    @include heading-title-20;
  }

  .help-text {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
