.status-text {
  color: #fff !important;
  text-transform: uppercase;
  padding: 6px;
  border-radius: 2px;
  &.active {
    background-color: #10b981;
  }
  &.error {
    background-color: #b94010;
  }
}
