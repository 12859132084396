@import '../../assets/scss/typography';

.code-setting-panel-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 24px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d3ddee;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 1;

  .section-label {
    padding: 8px 16px 0 16px;
    height: 40px;
    @include body-bold;
    color: $branding-sdc-500;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    width: 100%;
    background: linear-gradient(
      180deg,
      #eff3fa 0%,
      rgba(239, 243, 250, 0) 100%
    );
  }

  .qr-code-setting-body {
    width: 100%;

    &.no-bg {
      .qr-code-page-setting {
        margin-bottom: 22px;
      }
      .col,
      .checkbox-setting {
        padding: 0;
        background: none;
      }
    }
  }

  .qr-code-page-setting {
    margin: 4px 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 4px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    gap: 24px;
    background: $grey-100;
    border-radius: 4px;

    div .sdc-dropdown {
      display: block;

      button {
        width: 100%;
      }
    }

    @media screen and (max-width: $tablet-device-max-width) {
      gap: 4px;
      > .col {
        border-radius: 4px;
      }
    }
  }

  .qr-code-display-setting {
    margin: 4px 20px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: auto;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;

    label.sdc-checkbox {
      width: auto;
    }
  }
  .checkbox-setting {
    padding: 16px;
    background: $grey-100;
  }

  .col {
    padding: 16px;
  }
}
