@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.full-size-modal-component {
  position: fixed;
  left: 0;
  width: 100vw;
  z-index: 3;
  background-color: white;
  user-select: none;

  &.expanded {
    top: 0;
    height: 100%;
  }
  &.collapsed {
    bottom: 0;
  }

  .collapser {
    transition: all 0.6s ease-in-out;
    &.reverse {
      transform: rotateX(180deg);
    }
  }

  .resizer {
    position: absolute;
    cursor: ns-resize;
    width: 100%;
    height: 10px;
    z-index: 1;
  }

  .resizer-layout {
    position: fixed;
    cursor: ns-resize;
    background-color: rgba(186, 186, 186, 0.4);
    width: 100%;
    height: 5px;
    z-index: 2;
    display: none;
  }

  &.with-sidebar {
    left: 96px;
    width: calc(100vw - 96px);
  }

  .table-container {
    overflow: auto;
    background-color: $grey-100;
    height: calc(100% - 48px);
    @include scroll-style-default;
  }

  .header {
    height: 48px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $grey-200;

    .title {
      @include body-bold;
      color: $branding-sdc-500;
    }
    .actions {
      position: absolute;
      display: flex;
      gap: 10px;
      top: 9px;
      right: 24px;

      svg path {
        fill: $grey-600;
      }
    }
  }
}
