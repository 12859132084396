@import '../../assets/scss/global';

.user-initials {
  @include body-bold;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  width: 48px;
  height: 48px;
  background-color: white;
  color: $grey-800;
  line-height: 48px !important;
  &.random {
    @include body-small-bold;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    overflow: hidden;
    line-height: 33px !important;
    color: white;
  }
  &.pending {
    width: 32px;
    height: 32px;
    border: 1px dashed #1f6beb;
    border-radius: 48px;
  }
}
