@import '../../assets/scss/variables';

.loading-set {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 2rem;

  .spinner-grow {
    background-color: $primary;
  }
}
