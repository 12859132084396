@import '../../assets/scss/typography';

.access-key-page {
  position: relative;
  margin: 0;
  max-width: 100%;
  padding-top: 56px;
  background-color: white;
  height: 100%;

  .custom-taskbar {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .loader {
    padding-top: 2rem;
    .loader-set {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 8px;
      padding: 4px;
    }
    .text {
      display: flex;
      justify-content: center;
      padding-bottom: 8px;
      @include body-regular;
      color: $grey-800;
    }
  }

  .form-check {
    padding-left: 44px;
    height: 32px;
    line-height: 32px;
  }
  .form-check-input[type='radio']:checked {
    background-color: $branding-sdc-500;
  }
  .form-check-input {
    margin: 0;
    margin-right: 12px;
    width: 32px;
    height: 32px;
  }

  .separator {
    width: 100%;
    border-top: 1px solid $grey-400;
    margin-bottom: 6px;
  }
  .access-key-side-panel {
    position: absolute;
    top: 56px;
    right: 0;
    bottom: 0;
  }
  .name {
    display: flex;
    align-items: center;
    color: $grey-800;
  }
  .access-key-member-col {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    .name {
      @include body-small-bold;
    }
    .email {
      @include body-small;
      color: $grey-600;
    }
  }
  .access-key-role-col {
    .owner {
      @include body-small;
      padding: 6px 0px;
    }
    .dropdown-toggle::after {
      color: $info-500;
      margin-top: 9px;
      margin-left: 6px;
    }
    .sdc-dropdown.light button {
      padding: 0;
      @include body-small;
      background: transparent !important;
      &:hover {
        background: transparent !important;
      }
    }
  }

  .access-key-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
  }

  .team-management-data-grid > .data-grid-wrapper {
    margin-bottom: 0;
  }

  .pagination-navigator {
    @media only screen and (max-width: $tablet-device-max-width) {
      width: calc(100vw - 6%);
    }
  }

  .result-placeholder-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;

    img {
      width: fit-content;
    }
    text-align: center;

    .guide-content {
      @include heading-title-20;
      color: $branding-sdc-400;
      div {
        max-width: 30vw;
      }
    }
  }
}
