@import '/src/assets/scss/typography';
@import '/src/assets/scss/common';

// Credit: Code copied from https://codepen.io/ayanna/pen/ObLowr with modification
.filter-dropdown-container {
  @include body-small;
  .filter-dropdown {
    position: relative;

    .icon {
      margin-right: 4px;
    }

    &.open > .filter-dropdown-menu {
      display: block;
    }

    .filter-dropdown-toggle {
      @include body-small;
      display: inline-block;
      height: 24px;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      cursor: pointer;
      color: $grey-800;
      background-color: white;
      border: 1px solid $grey-400;
      border-radius: 4px;
      user-select: none;
      svg {
        vertical-align: text-top;
        path {
          fill: $info-500;
        }
      }

      &:disabled {
        pointer-events: none;
        border-color: $grey-200;
        color: $grey-500;
        background-color: $grey-100;
        box-shadow: none;
        svg path {
          fill: $grey-500;
        }
      }
    }
    &.open > .filter-dropdown-toggle {
      color: white;
      background: $info-500;
      transition: all 0.3s ease-in;
      svg {
        path {
          fill: white;
          transition: all 0.3s ease-in;
        }
      }
    }

    .filter-dropdown-menu {
      @include body-regular;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 160px;
      padding: 0;
      margin: 2px 0 0;
      font-size: 14px;
      background-color: white;
      border: 1px solid $grey-400;
      border-radius: 4px;
      box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
        0px 10px 15px -3px rgba(15, 23, 42, 0.1),
        0px 4px 6px -2px rgba(15, 23, 42, 0.05);
    }

    .filter-dropdown-item-container {
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 250px;
      text-align: left;
      list-style: none;
      padding: 0;
      @include scroll-style-default;

      li {
        cursor: pointer;
        :hover {
          background: $info-500;
          color: white;
        }
        & > a {
          display: block;
          padding: 5px 20px;
          clear: both;
          color: $grey-800;
          white-space: nowrap;
          text-decoration: none;
          text-overflow: ellipsis;
        }
      }

      .separator {
        padding: 4px 16px;
        cursor: default;
        @include body-italic;
        color: $grey-600;
        user-select: none;
      }
    }
  }

  .field-filter {
    padding: 8px 16px;
    background-color: $info-100;
    min-width: 300px;
    border-bottom: 1px solid $grey-400;
    .text-field-container {
      svg {
        fill: $grey-800;
        path {
          fill: $grey-800;
        }
      }
    }
  }
}
