@import '/src/assets/scss/typography';

/* Override bootstrap style*/

.table-row-dropdown-menu {
  .btn {
    padding: 0;
  }

  .dropdown-menu.show {
    min-width: 100%;
  }

  a.dropdown-item.disabled {
    pointer-events: none;
    color: $grey-500;
  }
  .dropdown-item:hover,
  .dropdown-item:active {
    color: white;
    background-color: $info-500;
  }

  .form-control:focus,
  .dropdown .btn:focus,
  .dropdown:focus {
    border-color: transparent;
  }

  /* Prevent starting a new line */
  .dropdown {
    display: inline;
  }

  .dropdown-menu,
  .dropdown-menu a {
    user-select: none;
    @include body-regular;
    text-decoration: none;
    color: $grey-800;

    &:active,
    &:focus {
      color: white !important;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
}
