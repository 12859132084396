@import '../../assets/scss/global';

.sdc-link-button {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0 8px;
  box-shadow: none;
  background-color: transparent;
  @include body-regular;
  text-decoration: underline;

  &.standard {
    color: $info-500;
    &:hover {
      color: $info-400;
    }
  }

  &.dark {
    color: $white;
    &:hover {
      color: $info-400;
    }
  }

  &:hover,
  &:active,
  &:focus,
  &:visited,
  &:focus-visible,
  &:focus-within,
  &:target,
  &:active:hover {
    outline: none !important;
  }
}
