@import '../../assets/scss/main';

.entity-view-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .entity-document-main-content {
    height: calc(100vh - 56px);
    position: relative;
    background: $white;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .result-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: none;
    padding: 32px;
    height: 100%;
  }

  // Overrrides
  .view-container {
    height: 100%;
  }

  // This removes the padding generated by the task bar
  // Since this page already leave some padding for it. The extra padding needs to be removed.
  .archive-document-container {
    padding: 0;
  }

  // Tablet resolution display
  @media screen and (max-width: $tablet-device-max-width) {
    .collapse-icon-container {
      top: 4px !important;
    }
  }
}
