@import '../../assets/scss/typography';

.edit-access-key-expiry-side-panel-component {
  overflow-x: hidden;
  height: 100%;
  padding: 16px;

  .sdc-dropdown button,
  .text-field-input {
    width: 100%;
  }
  .text-field-label:first-child {
    padding-top: 0;
  }

  .date-picker-container {
    position: relative;
  }

  .calendar-icon {
    position: absolute;
    right: 14px;
    top: 8px;
  }
}
