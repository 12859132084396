@import '../../assets/scss/typography';

.loading-wrapper {
  z-index: 9999;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  bottom: 0;
  opacity: 0.9;
  background-color: $backdrop;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @include heading-title-36;

  h1 {
    @include heading-title-32-bold;

    &.success {
      color: $success-500 !important;
    }
    &.error {
      color: $danger-500 !important;
    }
  }

  .archive-status {
    flex: 1;
  }

  .loading-status {
    position: relative;
    margin: auto;
    color: $white;
    h1 {
      margin-top: 50px;
    }
  }
}
