@import '/src/assets/scss/typography';

.zoom-control-group {
  button {
    margin-left: 8px !important;
    @include body-bold;
  }
  .custom-button {
    display: inline;
  }
  .sdc-dropdown button {
    margin-bottom: 3px;
  }

  .sdc-dropdown.dark button.small,
  .sdc-dropdown.light button.small,
  .sdc-dropdown.dark button.default,
  .sdc-dropdown.light button.default {
    @include body-bold;
  }
}
