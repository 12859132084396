@import '../../assets/scss/typography';

.pending-tray-action-pane-container {
  min-height: 52px;
  color: $grey-800;
  background-color: $branding-sdc-100;
  border: 1px solid $grey-400;
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;

  > * {
    white-space: nowrap;
  }

  .action-group {
    display: flex;
    gap: 10px;
    .icon-wrapper {
      cursor: pointer;
    }
    .icon-wrapper:disabled {
      cursor: not-allowed;
    }
  }

  .control-items {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    div {
      margin-left: 8px;
    }
    .sdc-dropdown {
      margin-left: 0 !important;
    }
  }

  .divider {
    margin: 0 8px;
    border-left: 1px solid $grey-400;
  }
}
