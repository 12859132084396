@import '../../assets/scss/typography';

.readonly-archive-document-container {
  overflow: hidden;
  background: $branding-sdc-900;
  height: 100%;

  .pdf-preview-container {
    flex: 1;
    position: relative;
    padding: 0;
    width: calc(100% - 360px);
    max-width: 100%;
    padding-bottom: $modal-footer-height;
  }

  .view-container {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .archive-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: $modal-footer-height;
    background: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    z-index: 3;
    box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
      -4px 0px 6px -1px rgba(15, 23, 42, 0.1),
      -2px 0px 4px -1px rgba(15, 23, 42, 0.06);

    .description {
      font-size: 11px;
      color: $white;
    }
    .document-name {
      color: $white;
      font-weight: bold;
    }
  }

  .file-name-header {
    position: absolute;
    bottom: 0;
    @include heading-title-20;
    width: 100%;
    padding: 24px 0px;
    color: $white;
    text-align: center;
  }

  .tag-section {
    padding: 0 16px 16px 16px;
  }
}
