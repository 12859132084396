@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.image-viewer-container {
  .image-frame {
    margin-top: 52px;
    height: calc(100vh - 200px);
    overflow-y: auto;
    @include scroll-style-default;

    img {
      display: block;
      margin: auto;
    }
  }
  .control-pane {
    text-align: center;
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    padding: 8px;
    background-color: $branding-sdc-900;
  }
  .control-pane-container {
    padding: 8px;
  }
}
