@import '../../../assets/scss/typography';

#sidebar-wrapper {
  background-color: $primary;
  text-align: center;
  min-height: 100vh !important;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  path {
    fill: white;
    width: 20px;
  }
}
#sidebar-wrapper .sidebar {
  width: 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $primary;
  color: $white;
  min-height: 100vh !important;
  z-index: 100;
  padding: 16px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  a.nav-link {
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 1rem 0;
    word-spacing: 60px;
    color: white !important;
    @include body-small-bold;
    &:hover {
      background-color: $branding-sdc-400;
    }
    &.active {
      background-color: $branding-sdc-400;
    }
  }

  .side-menu-settings {
    .dropdown-toggle {
      @include body-small-bold;
      display: flex;
      align-items: center;
      gap: 8px;
      color: white;
      margin: 0;
      &::after {
        display: none;
      }
      path {
        fill: white;
        width: 20px;
      }
    }
    .dropdown-menu {
      z-index: 2;
      position: fixed;
      left: 96px;
      top: auto;
      bottom: 46px;
      max-width: fit-content;
      padding: 0;
      border-radius: 0;
      background: none;
      min-width: 218px;
      border: none;
      background-color: $branding-sdc-500;
      .dropdown-divider {
        margin: 0;
      }
      .role {
        @include body-small;
        color: $branding-sdc-300;
      }
      .dropdown-item {
        @include body-regular;
        color: white;
        background-color: $branding-sdc-500;
        padding: 14px 16px;
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          max-width: 18px;
        }

        &.active,
        &:hover,
        &:focus {
          background-color: $branding-sdc-400 !important;
        }
      }
    }
  }
}
