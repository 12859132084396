@import '../../../assets/scss/global';

.application-menu-nav-title {
  font-weight: 700;
  font-size: 1rem;
  color: #fff !important;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin-left: 16px;
  // Tablet resolution display
  @media screen and (max-width: $tablet-device-max-width) {
    margin: 0;
  }
  .title-icon {
    border: none;
    padding: 0;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .description {
    @include body-italic;
  }

  .nav-dropdown.dropdown .dropdown-menu {
    left: 0;
    width: fit-content;
  }
}
.no-border {
  border: none !important;
}
