@import '../../assets/scss/typography';

.document-metadata {
  overflow-y: auto;

  .document-metadata-key {
    @include body-small-bold;
    color: $grey-800;
    padding: 16px 0 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .document-metadata-value {
    @include body-regular;
    color: $grey-800;
    border-bottom: 1px dashed #e2e8f0;
    padding: 0 0 8px 0;
  }

  .document-metadata-input {
    @include body-regular;
    color: $grey-800;
    border-bottom: 1px dashed #e2e8f0;
    padding: 8px 0;
  }

  .no-data {
    @include body-italic;
    color: $grey-600;
    padding: 0 0 8px 0;
  }

  .document-info-label {
    @include body-bold;
    color: $branding-sdc-500;
  }
}
