@import '../../assets/scss/global';

.unsupported-file-viewer-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5rem 5rem;
  overflow: hidden;
  @include heading-title-18;
  color: white;

  .title {
    @include heading-title-18-bold;
  }

  div {
    padding: 4px 4px;
  }

  .download-section {
    padding-top: 150px;
  }

  // Adjust paddings for smaller screen based on screen height
  @media screen and (max-height: 900px) {
    .download-section {
      padding-top: 30px;
    }
  }
}
