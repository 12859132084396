@import '/src/assets/scss/typography';

/*
* The tutorial from
* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_checkbox
*/
/* The grid-checkbox */
.grid-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  cursor: pointer;
  @include body-regular;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    width: 20px;
    transform: translate(0, -50%);
    border-radius: 4px;
    border: 1px solid $grey-400;
    background-color: white;

    /* Style the checkmark/indicator default*/
    &:after {
      left: 6px;
      top: 1px;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

/* On mouse-over, add a grey background color */
.grid-checkbox:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.grid-checkbox input:checked ~ .checkmark {
  transition-duration: 0.7s;
  transition: opacity 1;
  background-color: $branding-sdc-500;
}

/* Create the checkmark/indicator (hidden when not checked) */
.grid-checkbox .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.grid-checkbox input:checked ~ .checkmark:after {
  display: block;
}
