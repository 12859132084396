@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.pdf-previewer-container {
  canvas {
    margin: 0 auto;
    * > .react-pdf__Page__textContent {
      display: none;
    }
  }

  .document-preview-area {
    width: 100%;
    height: calc(100vh - 195px);
  }

  .react-pdf__message.react-pdf__message--no-data {
    margin: 16px auto;
    height: 100%;
    width: 50%;
    padding: 10px;
    text-align: center;
    font-size: 1.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-pdf__Page {
    min-width: 453px;
    min-height: auto;
    height: 100%;
  }

  .download-btn {
    position: absolute;
    z-index: 1;
    top: 35px;
    right: 11%;
  }

  .pdf-password-modal {
    z-index: 1;
  }

  .control-stick-bottom {
    top: 2rem;
  }

  .control-container {
    padding: 0.5rem 0;
  }

  .container {
    min-width: 453px;
    min-height: auto;
    height: 100%;
    cursor: grab;
    margin: auto;
    overflow: auto;
    @include scroll-style-default;
  }
}
