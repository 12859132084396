@import '../../assets/scss/typography';

.document-protection-page {
  position: relative;
  margin: 0;
  max-width: 100%;
  padding-top: 56px;
  background-color: white;
  height: 100vh;

  .page-title {
    span {
      margin-left: 8px;
    }
  }

  .document-protection-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 32px 32px 32px;
    flex: 1;
  }

  .help-header {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 16px;
  }

  .document-protection-page-container {
    padding-top: 0 !important;
    width: 100%;
    display: flex;
  }

  .hero {
    display: flex;
    align-items: center;
    padding: 0 0 8px 0;
    border-bottom: 2px solid $branding-sdc-400;

    .content {
      display: flex;
      flex-direction: column;
      justify-items: center;
      padding: 0 16px;

      .title {
        @include heading-title-20-bold;
        color: $branding-sdc-800;
      }
      .subtitle {
        @include body-regular;
        color: $branding-sdc-400;
      }
    }
  }

  .pagination-navigator {
    @media only screen and (max-width: $tablet-device-max-width) {
      width: calc(100vw - 6%);
    }
  }
}
