@import '/src/assets/scss/global';

.usage-card {
  padding: 32px;
  border-radius: 4px;
  border: 1px solid $grey-400;
  background: $white;
  width: 100%;

  div:nth-child(1) {
    color: $grey-600;
    @include body-regular;
  }
  div:nth-child(2) {
    font-weight: 600;
    padding-bottom: 8px;
    color: $grey-800;
    @include body-large;
  }
  div:nth-child(3) {
    padding-bottom: 18px;
    border-top: 1px solid $grey-400;
    width: 100%;
  }

  // Override bootstrp style
  .progress-container {
    .progress-bar {
      background-color: #3a9bd9;
      border: 1px solid #fff;
      border-radius: 0.25rem;
    }
    .progress {
      border: 1px solid #3a9bd9;
      background-color: transparent;
    }
  }
}
