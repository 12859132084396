@import '../../assets/scss/typography';

.tab-container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 100%;

  .icon {
    padding: 0 8px;
    display: flex;
    margin: auto;
  }

  @mixin badge-pill {
    @include body-small;
    line-height: 1.4;
    height: 18px;
    padding: 0 8px;
    border-radius: 16px;
    user-select: none;
    text-transform: uppercase;
    text-align: center;
    min-width: 20px;
    margin-left: 8px;
  }
  @mixin focus-state {
    color: $branding-sdc-500;
    .badge {
      @include badge-pill;
      background-color: $branding-sdc-200;
      color: $branding-sdc-500;
    }
    // Adjust paddings for smaller screen based on screen width
    @media screen and (max-width: $tablet-device-max-width) {
      @include heading-title-18-bold;
    }
  }

  .tab-item {
    @include body-bold;
    border-radius: 48px;
    color: white;
    border: none;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    padding: 8px;
    transition: background-color 0.5s ease;

    svg,
    path {
      fill: white;
    }
    .badge {
      @include badge-pill;
      background-color: $branding-sdc-200;
      color: $branding-sdc-500;
    }
    &.selected {
      @include focus-state;
      border: none;
      color: $branding-sdc-500;
      background: $branding-sdc-100;
      svg,
      path {
        fill: $branding-sdc-500;
      }
      .badge {
        @include body-small;
      }
    }
  }
}
