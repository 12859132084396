@mixin scroll-style-default {
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #a9bddc;
  }
}

.nav-dropdown {
  &.dropdown {
    display: flex;
    align-items: center;
    padding: 8px;

    .dropdown-toggle {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 8px;
      @include body-bold;
      color: white;
      &::after {
        display: inline-block;
        margin-left: 0.55em;
        margin-top: 1px;
        content: '';
        border-top: 0.3em solid white;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }
    }

    .dropdown-menu {
      z-index: 2;
      right: 0;
      left: auto;
      padding: 0;
      border-radius: 0;
      background: none;
      width: 218px;
      border: none;
      background-color: $branding-sdc-500;
      .dropdown-item {
        @include body-regular;
        color: white !important;
        background-color: $branding-sdc-500;
        padding: 14px 16px;
        width: 100%;
        &.active {
          @include body-bold;
          background-color: $branding-sdc-400 !important;
        }
        &:hover {
          @include body-bold;
          background-color: $branding-sdc-400 !important;
        }
        &:focus {
          @include body-bold;
          background-color: $branding-sdc-300 !important;
        }
      }
    }
  }
}
