@import '../../assets/scss/typography';

.error-container {
  text-align: center;
  margin-top: 80px;
  @include heading-title-36;
  img {
    width: 40%;
    height: 40%;
  }
  .error-message {
    font-size: 2.5rem;
    color: #6c757d;
  }
  .detail-message {
    font-size: 1.5rem;
    color: #909aa1;
  }
  .go-home {
    font-size: 1vw;
  }
}
