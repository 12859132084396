@import '../../assets/scss/typography';
@import '../../assets/scss/common';

// For exceed modal
.exceed-modal {
  ul {
    padding-left: 1rem;
    color: #475569;
    max-height: 200px;
    overflow: auto;
    margin-bottom: 26px;
    @include scroll-style-default;
  }
}

.upload-container {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;
  max-height: 100%;
  background-color: $info-100;
  padding: 40px 24px 24px 24px;

  .uploaded-file-total {
    padding: 24px;
    align-self: flex-start;
    color: $grey-700;
    &.have-footer {
      padding-bottom: 75px;
    }
  }

  .dropzone-container {
    display: flex;
    height: 80%;

    .dropzone {
      position: relative;
      text-align: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white;
      border: 1px solid $grey-400;
      box-sizing: border-box;
      border-radius: 8px;
      outline: none;
      transition: border 0.24s ease-in-out;

      &.selected-files {
        align-items: flex-start;
        border: 1px solid $grey-300;
        box-sizing: border-box;
      }

      &.drag-over {
        border-color: $white;
        background-image: linear-gradient(
            to right,
            $info-500 70%,
            transparent 30%
          ),
          linear-gradient(to right, $info-500 70%, transparent 30%),
          linear-gradient(to bottom, $info-500 70%, transparent 30%),
          linear-gradient(to bottom, $info-500 70%, transparent 30%);
        background-position: left top, left bottom, left top, right top;
        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
        background-size: 20px 3px, 20px 3px, 3px 20px, 3px 20px;
      }
    }
  }

  // Tablet resolution display
  @media screen and (max-width: $tablet-device-max-width) {
    .collapse-icon-container {
      top: 4px !important;
    }
  }
}
