@import '/src/assets/scss/global';

.subscription-card {
  display: grid;
  padding: 32px;
  grid-template-columns: auto auto;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  border-radius: 4px;
  border: 1px solid $grey-400;
  background: $white;
  width: 100%;

  .info {
    div:first-child {
      @include body-regular;
      color: $grey-600;
    }
    span {
      @include body-large-bold;
      color: $grey-800;
    }
  }
}
