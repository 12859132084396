@import './variables';
@import './typography';
@import './common';

.btn-primary {
  border: none;
}

html,
body {
  font-size: 14px;
}

.fullscreen {
  flex: 1;
  height: 100%;
}

input[type='text'] {
  max-height: 48px;
  border: 1px solid $grey-400;
}

input[type='text'] {
  &:hover,
  &:active,
  &:focus,
  &:visited,
  &:focus-visible,
  &:focus-within,
  &:target,
  &:active:hover {
    outline: none !important;
  }
}

input[type='text']::placeholder {
  color: #cbd5e1;
  opacity: 1; /* Firefox */
}

.form-select {
  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 1px #3b82f6;
  }
}

.page-container {
  padding-top: $header-height;
}

.form-control {
  &:focus {
    border: 1px solid $grey-400;
  }
}

.form-label {
  padding: 0;
  height: 43px;
  border-top: none;
  background: transparent;
}

.document-info-frame {
  padding: 0 16px 16px 16px;

  // This removes top gap from the first metadata row
  .row:first-child > div:first-child {
    padding: 0;
  }
}

.dropdown-menu::-webkit-scrollbar {
  background: white;
}

// Overwrite focus style of BS
.form-control:focus,
.dropdown .btn:focus,
.dropdown:focus {
  border-color: $info-400;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset,
    0px 0px 8px rgba($color: $info-400, $alpha: 0.5);
}

.text-field-input {
  border-radius: 4px;
  padding: 0 8px;
  height: 48px;
  color: $grey-800;
  border: 1px solid $grey-400;
  @include body-regular;
}

.text-field-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $grey-600;
  opacity: 1; /* Firefox */
  @include body-italic;
}

.text-field-input:focus {
  border-color: $info-400;
}

.text-field-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $grey-600;
  @include body-italic;
}

.text-field-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $grey-600;
  @include body-italic;
}

.text-field-input:disabled {
  background-color: $grey-100;
}

.text-field-label {
  @include body-small-bold;
  color: $grey-800;
  padding: 8px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Overwrites react-datepicker style
.react-datepicker {
  border: 1px solid $grey-400;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-bottom-color: $grey-400;
}

.react-datepicker__header {
  border-bottom: 1px solid $branding-sdc-500;
  background-color: $grey-200;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: $grey-200;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: $branding-sdc-500;
}

.new-tag {
  @include body-small-bold;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: $info-500;
  line-height: 1.6;
  color: white;
  padding: 0 8px;
}
