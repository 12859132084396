@import '../../assets/scss/typography';

.access-description-side-panel {
  text-align: left;
  padding: 16px;
  color: $grey-800;
  @include body-regular;

  .role {
    @include heading-title-20-bold;
    padding-bottom: 8px;
    color: $branding-sdc-500;
  }
}
