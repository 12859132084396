@import '../../assets/scss/typography';

.new-access-key-modal {
  .modal-body {
    @include heading-title-18;
    color: $grey-800;
    border-top: 1px solid #ebeefa;
    border-bottom: 1px solid #ebeefa;
    background-color: #fff;
    padding: 16px 16px 3rem 16px;
    // Makes the expiration date section in good shape
    .text-field-input,
    .sdc-dropdown button {
      width: 100%;
    }
  }
  .modal-content {
    border: none;
  }

  .modal-header {
    padding: 16px;
    border: none;
    @include heading-title-20-bold;
    background-color: $branding-sdc-500;
    color: white;
    &.success {
      background-color: $success-500;
    }
  }

  .modal-footer {
    border: none;
    .primary {
      width: 100%;
    }
  }

  .modal-dismiss-button {
    padding: 0;
  }

  .modal-title {
    @include heading-title-20-bold;
  }

  .modal-body {
    padding-bottom: 16px;
  }

  button.close-btn {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 1rem;
    background: none !important;
  }

  .btn {
    border-radius: 0.2rem !important;
  }

  .close-button {
    position: absolute;
    right: 14px;
    top: 14px;
    background: none;
    border: none;
    cursor: pointer;
    svg {
      fill: white;
    }
  }

  // Override datepicker style. Move the arrow to the left to point to the text field.
  .react-datepicker__tab-loop {
    .react-datepicker__triangle {
      left: -80% !important;
    }
  }

  .date-picker-container {
    position: relative;
  }

  .calendar-icon {
    position: absolute;
    right: 14px;
    top: 8px;
  }
}
