@import '../../assets/scss/variables';

.collapse-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: $grey-100;
  padding: 10px 16px 10px 10px;
  border-radius: 4px 0 0 4px;

  &.no-title-bar {
    top: 4px !important;
  }
  &.with-title-bar {
    top: 61px !important;
  }
  &.with-title-bar-and-task-bar {
    top: 108px !important;
  }
  &.with-title-bar-large {
    top: 67px !important;
  }
  &.with-title-bar-and-task-bar-large {
    top: 116px !important;
  }
  &.with-title-bar-and-task-bar-and-pending-tray {
    top: 156px !important;
  }

  .frame {
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
  }
  .box {
    position: relative;
    top: 0;
    left: 0;
  }
  .arrow {
    position: absolute;
    top: 8px;
    left: 4px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    &.reverse {
      transform: rotateY(180deg);
    }
  }
}
