@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.result-document-view-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);

  .pagination {
    display: flex;
    align-items: flex-end;
  }

  .document-view-header {
    padding-bottom: 8px;

    & span {
      color: $grey-600;
    }
  }

  .collapsable-document-view-header {
    @extend .document-view-header;
    margin-bottom: 8px;

    .title {
      display: grid;
      width: 100%;
      grid-template-columns: auto auto;
      border-bottom: 3px solid $branding-sdc-400;
      padding-bottom: 4px;

      .start {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .end {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
      }
    }
    .content {
      padding-top: 16px;
      display: none;
    }
    &.visible {
      .content {
        display: block;
        animation: fadeInFromNone 0.4s ease-out;
      }
    }
    .display-name {
      @include heading-title-20-bold;
      color: $grey-900;
    }
  }

  .items-container {
    @include scroll-style-default;
    padding: 4px 0;
    overflow-y: auto;
  }

  .view-actions {
    min-height: 52px;
    color: $grey-800;
    background-color: $branding-sdc-100;
    border: 1px solid $grey-400;
    border-radius: 4px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    padding: 8px 20px;

    > * {
      white-space: nowrap;
    }

    .action-group {
      display: flex;
      gap: 10px;
      .icon-wrapper {
        cursor: pointer;
      }
      .icon-wrapper:disabled {
        cursor: not-allowed;
      }
    }

    .control-items {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      div {
        margin-left: 8px;
      }
      .sdc-dropdown {
        margin-left: 0 !important;
      }
    }

    .divider {
      margin: 0 8px;
      border-left: 1px solid $grey-400;
    }
  }

  .result-count {
    padding-bottom: 8px;
    @include body-regular;
    color: $info-500;
    user-select: none;
  }

  .search-placeholder-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    overflow-y: visible;

    img {
      width: fit-content;
    }
    text-align: center;

    .guide-content {
      @include heading-title-20;
      color: $branding-sdc-400;
    }
    .not-found-content {
      @extend .guide-content;
      @include heading-title-20-bold;
    }
  }

  .result-placeholder-container {
    padding-top: 10vh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;

    img {
      width: fit-content;
    }
    text-align: center;

    .guide-content {
      @include heading-title-20;
      color: $branding-sdc-400;
    }
    .not-found-content {
      @extend .guide-content;
      @include heading-title-20-bold;
    }
    // Small laptop screen
    @media screen and (max-height: calc($low-res-device-max-height + 150px)) {
      padding-top: 7vh;
      img {
        width: 25vh;
      }
      .not-found-content {
        @include body-bold;
      }
    }
    // Low resolution screen
    @media screen and (max-height: $low-res-device-max-height) {
      display: none;
    }
  }
  .simple-result-placeholder-container {
    width: 100%;
    height: 100%;
    user-select: none;
    text-align: left;
    color: $grey-600;
    padding: 8px 0;
    @include body-italic;
    display: none;

    // Tablet resolution display
    @media screen and (max-height: $low-res-device-max-height) {
      display: block;
    }
  }

  @keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }
}
