@import '../../assets/scss/typography';

.help-action-icon {
  padding: 0;

  button {
    border: none;
    box-shadow: none;
    cursor: pointer;
    background-color: transparent;
    color: $grey-600;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
  }

  svg {
    fill: $grey-600;
  }

  :hover,
  button:hover {
    color: $grey-800;
    svg {
      fill: $grey-800;
    }
  }
}
