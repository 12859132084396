@import '/src/assets/scss/typography';

.pagination-navigator {
  display: inline-grid;
  grid-template-columns: auto auto;
  margin-top: 16px;
  padding: 10px 16px;
  width: 100%;
  background-color: $grey-100;
  border: 1px solid $grey-400;
  border-radius: 4px;

  & span:first-child {
    color: $grey-700;
    align-self: center;
  }

  & span:last-child {
    float: right;
    text-align: right;
  }

  button {
    display: inline;
  }
}
