@import '../../assets/scss/global';

.tag-item {
  @include body-small;
  word-break: break-all;
  line-height: 15px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px 3px;
  border-radius: 14px;
  min-height: 25px;
  color: white;
  user-select: none;
  margin: 0 4px;
  background: $info-500;

  button {
    padding: 0;
    background: transparent;
    outline: none !important;
    box-shadow: none;
    border: none;
    display: flex;
    align-items: center;
    svg {
      width: 15px;
      height: 15px;
      padding-bottom: 1px;
    }
  }
}
