@import '../../../assets/scss/typography';
@import '../../../assets/scss/common';

.access-key-table {
  flex: 1;
  display: flex;
  height: 100%;
  overflow: auto;
  @include scroll-style-default;

  .tag {
    color: $grey-600;
  }

  .name {
    display: flex;
    flex-direction: row;
  }

  .pen {
    min-width: 12px;
    min-height: 12px;
  }

  table {
    min-height: 420px;
  }

  .selected {
    background-color: $info-100;
  }
  // Make the table scrollable
  .data-row-container {
    height: calc(100vh - 300px);
    min-height: 65vh;
  }
}
