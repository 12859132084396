@import '../../assets/scss/typography';
@import '../../assets/scss/common';

#sortTable {
  max-width: calc(100vw - 64px);
  @include scroll-style-default;
}

.data-grid-wrapper {
  width: 100%;
  height: 100%;
  background: $white;

  .data-header {
    @include body-bold;
    background-color: $grey-100;
    user-select: none;
    color: $grey-800;
  }

  .data-row,
  .data-header {
    min-height: 57px;
    display: flex;
    border-bottom: 1px solid $grey-400;
    align-items: center;
  }
  .data-menu {
    width: 64px;
  }
  .data-col {
    // Keep this for debug
    flex: 1;
    padding-right: 10px;
    white-space: nowrap;
    width: 100%;
    min-width: 150px;
    text-overflow: ellipsis;
    &.f-2 {
      flex: 2;
    }
  }
  .data-check {
    padding: 0 16px;
  }
  .data-row-container {
    overflow-y: auto;
    overflow-x: auto;
    @include scroll-style-default;
  }
  .data-row:hover {
    background-color: $info-100;
  }
  .selected {
    background-color: $info-100;
  }

  .clickable {
    cursor: pointer !important;
  }
  .padded-column {
    padding-left: 8px;
  }

  .result-placeholder-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-top: 10vh;

    img {
      width: 35vh;
    }
    text-align: center;

    .guide-content {
      @include heading-title-20;
      color: $branding-sdc-400;
    }
    .not-found-content {
      @extend .guide-content;
      @include heading-title-20-bold;
    }

    // Tablet resolution display
    @media screen and (max-height: $low-res-device-max-height) {
      display: none;
    }
    // Small laptop screen
    @media screen and (max-height: calc($low-res-device-max-height + 150px)) {
      padding-top: 7vh;
      img {
        width: 25vh;
      }
      .not-found-content {
        @include body-bold;
      }
    }
  }
  .simple-result-placeholder-container {
    width: 100%;
    height: 100%;
    user-select: none;
    text-align: left;
    color: $grey-600;
    padding: 8px 0;
    @include body-italic;
    display: none;

    // Tablet resolution display
    @media screen and (max-height: $low-res-device-max-height) {
      display: block;
    }
  }
}
