@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.side-panel {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  position: relative;
  padding: 0;
  border-left: 1px solid $grey-400;
  background: $grey-100;
  overflow: hidden;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
  box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
    -4px 0px 6px -1px rgba(15, 23, 42, 0.1),
    -2px 0px 4px -1px rgba(15, 23, 42, 0.06);

  .resizer {
    position: absolute;
    cursor: ew-resize;
    width: 10px;
    height: 100%;
  }

  .resizer-layout {
    position: fixed;
    cursor: ew-resize;
    width: 5px;
    background-color: rgba(186, 186, 186, 0.4);
    height: 100%;
    z-index: 2;
    display: none;
  }

  .section-label {
    padding: 8px 16px 0 16px;
    height: 40px;
    @include body-bold;
    color: $branding-sdc-500;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    &.clear {
      padding: 0;
    }
  }

  .section-divider {
    width: 100%;
    border-top: 1px solid $grey-400;
    margin-bottom: 6px;
  }

  .document-type {
    @include body-bold;
    color: $grey-800;
    padding: 0px 16px 16px 16px;

    button {
      width: 100%;
    }
  }

  .nothing-selected {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    white-space: nowrap;
    user-select: none;
    .title {
      @include body-bold;
    }
  }

  // This allows the metadata pane to clip and scroll
  .document-property-container {
    overflow-y: auto;
    @include scroll-style-default;
    flex: 1;
    .full {
      max-height: 100%;
    }
  }

  .side-bar-collapser {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid $grey-400;
    margin-bottom: 6px;
    line-height: 50px;
    padding-left: 16px;
    @include body-bold;
    color: $grey-800;
  }

  .collapse-icon {
    position: fixed;
    right: 0;
    top: 0;
    // Tablet resolution display
    @media screen and (max-width: $tablet-device-max-width) {
      top: 64px;
    }
  }

  .end-of-data {
    .separator {
      width: 50px;
      border-top: 1px solid $grey-400;
      margin-bottom: 6px;
    }
    @include body-small-italic;
    color: $grey-500;
    padding: 8px 16px;
    user-select: none;
  }

  .footer {
    padding: 14px 34px;
    background-color: white;
    box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
      0px -4px 6px -1px rgba(15, 23, 42, 0.1),
      0px -2px 4px -1px rgba(15, 23, 42, 0.06);

    .loader {
      min-height: 48px;
      .loader-set {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 8px;
        padding: 16px 4px;
      }
    }
  }

  .action-item {
    display: block;
    width: 100%;
  }

  // Tablet resolution display
  @media screen and (max-width: $tablet-device-max-width) {
    position: fixed;
    right: 0;
    height: 100%;
  }
}
