@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.archive-success-notification {
  &.popover {
    background: transparent;
    border: none;
    outline: none;
  }
  .popover-arrow {
    display: none !important;
  }
  .popover-body {
    padding: 0;
  }

  .complete-document-notification {
    position: absolute;
    top: -110px;
    left: 180px;
    min-width: 320px;
    width: fit-content;
    color: white;
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    background: $success-500;
    box-sizing: border-box;
    box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
      0px 4px 6px -1px rgba(15, 23, 42, 0.1),
      0px 2px 4px -1px rgba(15, 23, 42, 0.06);
    border-radius: 4px;
    .notification-arrow {
      position: absolute;
      bottom: -19px;
    }
    .notification-close-btn {
      padding: 0;
      line-height: 14px;
      img {
        width: 14px;
      }
    }
    .header {
      @include heading-title-18-bold;
    }
  }
}

.complete-archive-document-footer {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  max-height: 80px;
  transition: max-height 0.35s ease-out;
  background: $success-100;
  padding: 12px 16px;
  width: 100%;
  color: $success-600;
  border: 1px solid $success-400;
  @include body-bold;

  .label-group {
    cursor: pointer;
    display: flex;
    gap: 10px;
    .icon-box {
      flex: 1;
      text-align: right;
    }
    .flip {
      transform: scaleY(-1);
      transition: all 0.45s ease-in-out;
    }
  }

  &.expanded {
    max-height: 200px;
    transition: max-height 0.45s ease-in;
  }

  .btn-footer-label {
    text-decoration: underline;
    color: $success-600;
    img {
      width: 14px;
    }
    &:hover {
      color: $success-800;
    }
  }

  .btn-success-file {
    flex: 1;
    text-decoration: underline;
    color: $success-600;
    img {
      width: 14px;
    }
    &:hover {
      color: $success-800;
    }
  }
  .complete-list {
    display: flex;
    align-items: center;
    gap: 4px;
    > div:first-child() {
      color: $grey-800;
    }
    > .btn {
      text-align: left;
      color: $info-500;
    }
    .item-content {
      display: block;
      max-width: 85vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .complete-list:not(:first-child) {
    border-top: 1px solid $grey-400;
  }
  .complete-document-container {
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    @include scroll-style-default;

    &::-webkit-scrollbar-thumb {
      background-color: $success-600;
    }

    display: flex;
    flex-direction: column;
  }
  .complete-counter-number {
    color: white;
    background-color: $success-500;
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    user-select: none;
    @include body-regular;
  }

  @keyframes fadeInUp {
    from {
      transform: translate(0, 40px, 0);
    }

    to {
      transform: translate(0, 0, 0);
      opacity: 1;
    }
  }

  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
  }

  .animatedFadeInUp {
    opacity: 0;
  }

  .fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
  }
}
