@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.uploaded-document-container {
  width: 100%;
  height: 100%;
  flex-direction: row;
  overflow: auto;
  position: relative;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background: none;
  transition: border 0.24s ease-in-out;
  cursor: default;
  > * {
    margin-right: 64px;
    margin-bottom: 48px;
  }

  &.list {
    flex-flow: column;
    flex: initial;
    * {
      margin: 0;
    }
    .document-thumbnail-container.list:nth-child(2) {
      border-top: 1px solid $grey-400;
    }
  }

  @include scroll-style-default;
}
