@import '../../assets/scss/typography';

.data-table-component {
  @include body-small;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tr,
  td,
  th {
    border: 1px solid $grey-400;
  }

  th {
    @include body-small-bold;
    padding: 8px;
    user-select: none;
  }

  // Remove any padding
  .clear {
    padding: 0;
  }

  tr:hover {
    background: $info-100;
    .table-row-dropdown-menu {
      display: inline;
    }
  }

  tr th {
    background: $grey-100;
    min-width: 32px;
  }

  .system-column {
    background: $grey-100;
    min-width: 32px;
    text-align: center;
  }

  // Override - hide the options by default
  .table-row-dropdown-menu {
    display: none;
  }

  .custom-tooltip {
    border-radius: 4px;
    @include body-small;
  }
}
