@import '/src/assets/scss/typography';
@import '/src/assets/scss/common';

// Credit: Code copied from https://codepen.io/ayanna/pen/ObLowr with modification
.radio-dropdown-container {
  @include body-regular;
  .radio-dropdown {
    position: relative;

    .icon {
      margin-right: 4px;
    }

    .radio-dropdown-toggle {
      @include body-regular;
      display: grid;
      align-items: center;
      grid-template-columns: auto 22px;
      height: 32px;
      text-align: left;
      white-space: nowrap;
      vertical-align: middle;
      cursor: pointer;
      color: $grey-800;
      background-color: white;
      border: 1px solid $grey-400;
      border-radius: 4px;
      user-select: none;
      padding: 0 16px;

      .caret {
        margin-left: 4px;
      }

      &:disabled {
        pointer-events: none;
        border-color: $grey-200;
        color: $grey-500;
        background-color: $grey-100;
        box-shadow: none;
        svg path {
          fill: $grey-500;
        }
      }
    }

    // Info variant
    .radio-dropdown-toggle {
      background-color: transparent;
      &.info {
        border: none;
        svg {
          path {
            fill: $info-500;
          }
        }
      }
    }

    .radio-dropdown-menu {
      @include body-regular;
      position: absolute;
      top: auto;
      left: 0;
      z-index: 9999;
      float: left;
      min-width: 300px;
      padding: 0;
      margin: 2px 0 0;
      font-size: 14px;
      background-color: white;
      border: 1px solid $grey-400;
      border-radius: 4px;
      box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
        0px 10px 15px -3px rgba(15, 23, 42, 0.1),
        0px 4px 6px -2px rgba(15, 23, 42, 0.05);
    }

    .radio-dropdown-menu {
      &.up {
        bottom: 100%;
      }
    }

    .apply-container {
      border-top: 1px solid $grey-200;
      padding: 16px;
      button {
        width: 100%;
      }
    }

    .description {
      padding: 16px;
      background-color: $grey-100;
      @include body-regular;
      color: $grey-800;
    }

    .option-item-container {
      // min-height: 40px;
      border-top: 1px solid $grey-200;
      padding: 8px 16px;
      width: 100%;
      .option-input-container {
        padding: 8px 0 0 24px;
      }
    }

    .radio-dropdown-item-container {
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 360px;
      text-align: left;
      list-style: none;
      margin: 0;
      padding: 0;
      @include scroll-style-default;

      li {
        cursor: pointer;
        :hover {
          background: $info-500;
          color: white;
        }
        & > a {
          display: block;
          padding: 5px 20px;
          clear: both;
          color: $grey-800;
          white-space: nowrap;
          text-decoration: none;
          text-overflow: ellipsis;
        }
      }

      .separator {
        padding: 4px 16px;
        cursor: default;
        @include body-italic;
        color: $grey-600;
        user-select: none;
      }
    }
  }
}
