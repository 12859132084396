@import '../../assets/scss/typography';

.user-invitation-modal-container {
  .modal-body {
    @include heading-title-18;
    color: $grey-800;
    border-top: 1px solid #ebeefa;
    border-bottom: 1px solid #ebeefa;
    background-color: #fff;
    padding: 16px 16px 3rem 16px;
  }

  .modal-content {
    border: none;
  }

  .modal-header {
    padding: 16px;
    border: none;
    @include heading-title-20-bold;
    background-color: $branding-sdc-500;
  }

  .modal-footer {
    border: none;
  }

  .modal-dismiss-button {
    padding: 0;
  }

  .modal-title {
    @include heading-title-20-bold;
    color: white;
  }

  .modal-body {
    padding-bottom: 16px;
  }

  .btn {
    border-radius: 0.2rem !important;
  }

  button.sdc-link-button.standard {
    text-decoration: none;
  }

  .invitation-modal {
    .invite-body {
      flex-direction: column;
      .sdc-dropdown.light button {
        width: 100%;
      }
      .col {
        margin-bottom: 16px;
      }
    }
  }
  .invite-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sdc-link-button {
      text-decoration: underline !important;
    }
    .custom-button {
      width: 134px;
    }
  }
  .close-button {
    position: absolute;
    right: 14px;
    top: 14px;
    background: none;
    border: none;
    cursor: pointer;
    svg {
      fill: white;
    }
  }
}
