@import '/src/assets/scss/typography';

.unsupported-file-previewer-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  @include body-small;
  color: $grey-800;
  padding: 16px;

  .unsupported-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 32px;
    background-color: $grey-300;
    border-radius: 4px;
    border: 1px dashed $grey-600;
    @include body-small;
    width: 100%;
    height: 230px;
    color: $grey-800;
  }
  .actions {
    @include body-regular;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 16px;

    button {
      padding: 0 4px;
    }
  }
}
