@import '../../assets/scss/typography';

.toaster-container {
  .title {
    @include body-large-bold;
  }

  .message {
    @include body-regular;
  }

  .Toastify__toast-container {
    width: 400px;
  }

  .toaster-content {
    display: grid;
    grid-template-columns: 20px auto;
    gap: 5px;

    .toaster-icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  // -------------- Primary -------------- //
  .primary-success {
    color: white;
    background: $success-500;
    svg,
    path {
      fill: white;
    }
  }
  .primary-warning {
    color: white;
    background: $warning-500;
    svg,
    path {
      fill: white;
    }
  }
  .primary-info {
    color: white;
    background: $info-500;
    svg,
    path {
      fill: white;
    }
  }
  .primary-danger {
    color: white;
    background: $danger-500;
    svg,
    path {
      fill: white;
    }
  }
  .primary-basic {
    color: $grey-800;
    background: white;
    svg,
    path {
      fill: $grey-800;
    }
  }

  // -------------- Secondary -------------- //
  .secondary-success {
    color: $success-900;
    background: $success-100;
    border: 1px solid $success-500;
    .toaster-content {
      border: none;
    }
    svg,
    path {
      fill: $success-500;
    }
  }
  .secondary-warning {
    color: $warning-900;
    background: $warning-100;
    border: 1px solid $warning-500;
    .toaster-content {
      border: none;
    }
    svg,
    path {
      fill: $warning-500;
    }
  }
  .secondary-info {
    color: $info-900;
    background: $info-100;
    border: 1px solid $info-500;
    .toaster-content {
      border: none;
    }
    svg,
    path {
      fill: $info-500;
    }
  }
  .secondary-danger {
    color: $danger-900;
    background: $danger-100;
    border: 1px solid $danger-500;
    .toaster-content {
      border: none;
    }
    svg,
    path {
      fill: $danger-500;
    }
  }
  .secondary-basic {
    color: $grey-800;
    background: $grey-100;
    border: none;
    svg,
    path {
      fill: $grey-800;
    }
  }

  // -------------- Soft -------------- //
  .soft-success {
    color: $success-800;
    background: white;
    border-left: 2px solid $success-500;
    .toaster-content {
      border: none;
    }
    div svg,
    div path {
      fill: $success-500;
    }
  }
  .soft-warning {
    color: $warning-800;
    background: white;
    border-left: 2px solid $warning-500;
    .toaster-content {
      border: none;
    }
    div svg,
    div path {
      fill: $warning-500;
    }
  }
  .soft-info {
    color: $info-800;
    background: white;
    border-left: 2px solid $info-500;
    .toaster-content {
      border: none;
    }
    div svg,
    div path {
      fill: $info-500;
    }
  }
  .soft-danger {
    color: $danger-800;
    background: white;
    border-left: 2px solid $danger-500;
    .toaster-content {
      border: none;
    }
    div svg,
    div path {
      fill: $danger-500;
    }
  }
  .soft-basic {
    color: $grey-800;
    background: $grey-100;
    border-left: 2px solid $grey-500;
    .toaster-content {
      border: none;
    }
    div svg,
    div path {
      fill: $grey-800;
    }
  }
}
