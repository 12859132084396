@import '../../assets/scss/typography';

.cloud-icon-container {
  .frame {
    padding: 3rem 2rem 2rem 2rem;
    position: relative;
    top: 0;
    left: 0;
  }
  .cloud {
    position: relative;
    top: 0;
    left: 0;
  }
  .check-mark {
    position: absolute;
    top: 30%;
    left: 35%;
  }
  .text {
    position: absolute;
    top: 265px;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 28px;
    color: $success-500;
    @include heading-title-24-bold;
  }
}
