@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.code-page-container {
  padding-top: 56px;
  padding-bottom: 84px;

  .code-container {
    display: flex;
    flex-direction: row;
  }

  .code-preview-container {
    width: 100%;
    max-height: 76vh;
    padding: 20px;
    overflow-y: auto;
    @include scroll-style-default;

    .code-screen-header {
      @include heading-title-20-bold;

      color: $grey-900;

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .code-screen-subheader {
      @include body-small;

      color: $grey-800;

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
      margin-bottom: 10px;
    }
  }

  .capture-side-panel {
    height: calc(100vh - 185px) !important;
  }

  @media screen and (max-width: $tablet-device-max-width) {
    .code-page-container {
      padding-bottom: 84px;
    }
    .collapse-icon-container {
      background: $white;
    }
    .code-preview-container {
      padding: 64px 20px 20px;
    }

    .side-panel {
      // Tablet resolution display
      position: relative;
      z-index: 0;
      right: 0;
      top: 0 !important;
      height: 100%;
      background-color: $white;
    }
  }
}
