@import '../../../assets/scss/typography';

.team-management-data-grid {
  flex: 1;
  display: flex;
  height: 100%;
  overflow: auto;

  // Make the table scrollable
  .data-row-container {
    height: calc(100vh - 447px);
    min-height: 65vh;
  }

  .pending-tag {
    /* Status Tag */

    box-sizing: border-box;

    /* Auto layout */

    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;

    width: 88px;
    height: 19px;

    /* Grey/200 */

    background: $grey-200;
    /* Grey/200 */

    border: 1px solid $grey-200;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    span.rectangle {
      /* Rectangle 1780 */

      width: 6px;
      height: 6px;

      /* Grey/600 */

      background: $grey-600;
      border-radius: 1px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    span.text {
      /* Label */

      width: 60px;
      height: 19px;

      font-family: OpenSans;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;
      text-overflow: clip;
      overflow: visible;

      @include body-small-bold;

      /* Grey/600 */

      color: $grey-600;

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  .pending-email {
    @include body-small-bold;
    color: $grey-800;
  }

  table {
    min-height: 420px;
  }

  .selected {
    background-color: $info-100;
  }
}
