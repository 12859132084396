@import '../../assets/scss/typography';

.edit-container-access-side-panel-container {
  overflow-x: hidden;
  .row {
    padding: 16px;
  }
  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    @include body-small-bold;
    color: $branding-sdc-500;
  }
  .label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .input-box {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
  }

  .separator {
    width: 100%;
    border-top: 1px solid $grey-400;
    margin-bottom: 6px;
  }
}
