@import '../../assets/scss/global';

.uploaded-card-container {
  position: relative;
  gap: 18px;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;

  &.uploading {
    background: $info-100;
    border: 1px solid $info-200;
    .file-info,
    span {
      color: $grey-800;
      word-break: break-word;
      @include body-bold;
    }

    &.success {
      background: $success-100;
      border: 1px solid $success-200;
      .file-info,
      .file-status {
        color: $success-600;
        word-break: break-word;
        @include body-bold;
      }
      svg path {
        fill: $success-600;
      }
    }
  }

  &.queuing {
    background: $info-100;
    border: 1px solid $info-200;
    .file-info,
    span {
      color: $grey-600;
      @include body-italic;
    }
  }

  &.failed {
    background: $danger-100;
    border: 1px solid $danger-200;
    span {
      color: $danger-500;
      @include body-bold;
    }
  }

  &.duplicated {
    background: $danger-100;
    border: 1px solid $danger-200;
    span {
      color: $danger-500;
      @include body-bold;
    }
  }

  .file-info-wrapper {
    flex: 1 1;
    gap: 5px;
    display: flex;
    position: relative;
    .file-info {
      width: 83%;
      word-break: break-all;
      @include body-bold;
    }
    .file-size {
      flex: 1;
      align-self: flex-start;
      text-align: right;
      color: $grey-600;
      word-break: break-word;
      @include body-regular;
    }
    .file-failed {
      color: $danger-500;
      @include body-regular;
    }
    .file-duplicated {
      color: $danger-500;
      @include body-regular;
    }
    .file-queuing {
      color: $grey-600;
      @include body-italic;
    }
    .close-file-upload {
      cursor: pointer;
      flex: 1;
      text-align: right;
      align-self: center;
      .dismiss-btn {
        color: $grey-800;
      }
    }

    svg {
      margin: 3px 0;
    }
  }

  .loading {
    opacity: 0.4;
  }

  .upload-progress {
    display: flex;
    gap: 8px;
    margin: auto;
    color: $info-500;
    align-items: center;
    @include body-regular;

    .progress {
      height: 5px;
      width: 90px;
      flex: 1;
      border: none;
      border-radius: 10px;
      .progress-bar {
        background-color: $info-500;
      }
    }

    &.success {
      color: $success-500;
      * > .progress-bar {
        background-color: $success-500;
      }
    }
  }
}
