@import '../../assets/scss/typography';
@import '../../assets/scss/common';

.trash-table {
  @include body-regular;
  color: $grey-800;
  height: 100%;

  // Make the table scrollable
  .data-row-container {
    height: calc(100vh - 447px);
    min-height: 35vh;
  }

  .document-type {
    background-color: $grey-200;
    border: 1px solid $grey-400;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 0px 8px;
    width: fit-content;
    white-space: pre-wrap;
    @include body-bold;
  }
  .title-content {
    > * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    div:first-child {
      @include body-bold;
    }
    div:last-child {
      color: $grey-700;
      @include body-small;
    }
  }
  .delete-by-col {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .action-button-container {
    display: grid;
    grid-template-columns: auto auto;
    padding: 8px 0;
    .start {
      display: flex;
      width: 100%;
      div,
      button {
        margin-right: 8px;
      }
    }
    .end {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .selected-row {
    background-color: $info-100;
  }
}
