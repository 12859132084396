@import '../../assets/scss/typography';

.default-modal-container {
  .modal-body {
    @include body-large;
    color: $grey-800;
    border-top: 1px solid #ebeefa;
    border-bottom: 1px solid #ebeefa;
    background-color: #fff;
    padding: 16px 16px 3rem 16px;

    .checkbox {
      margin-top: 10px;
    }
  }

  .modal-content {
    border: none;
  }

  .modal-header {
    padding: 16px;
    border: none;
    @include heading-title-20-bold;
    background-color: $branding-sdc-500;
  }

  .modal-footer {
    border: none;
  }

  .modal-dismiss-button {
    padding: 0;
  }

  .modal-title {
    @include heading-title-20-bold;
  }

  .modal-body {
    padding-bottom: 16px;
  }

  button.close-btn {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 1rem;
    background: none !important;
  }

  .btn {
    border-radius: 0.2rem !important;
  }

  button.sdc-link-button.standard {
    text-decoration: none;
  }

  button.custom-button.warning.default {
    color: $warning-900;
  }

  &.warning {
    .modal-content {
      .modal-header {
        color: $warning-900;
        background-color: $warning-500;
      }
    }
  }

  .invitation-modal {
    .invite-body {
      flex-direction: column;
      .sdc-dropdown.light button {
        width: 100%;
      }
      .col {
        margin-bottom: 16px;
      }
    }
  }
  .invite-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sdc-link-button {
      text-decoration: underline !important;
    }
    .custom-button {
      width: 134px;
    }
  }

  .close-button {
    position: absolute;
    right: 14px;
    top: 14px;
    background: none;
    border: none;
    cursor: pointer;
    svg {
      fill: white;
    }
  }

  // Variant styles
  &.primary-default {
    .modal-header {
      color: white;
      background-color: $branding-sdc-500;
    }
  }
  &.primary-warning {
    .modal-header {
      color: $warning-900;
      background-color: $warning-500;
    }
  }
  &.primary-info {
    .modal-header {
      color: white;
      background-color: $info-500;
    }
  }
  &.primary-danger {
    .modal-header {
      color: white;
      background-color: $danger-500;
    }
  }

  &.secondary-default {
    .modal-header {
      color: $grey-900;
      background-color: white;
      padding-top: 32px;
      padding-bottom: 4px;
    }
    .modal-body {
      border: 0;
      padding: 0 16px;
    }
  }
  &.secondary-info {
    .modal-header {
      color: $info-600;
      background-color: white;
      padding-top: 32px;
      padding-bottom: 4px;
    }
    .modal-body {
      border: 0;
      padding: 0 16px;
    }
  }
  &.secondary-warning {
    .modal-header {
      color: $grey-900;
      background-color: white;
      padding-top: 32px;
      padding-bottom: 4px;
    }
    .modal-body {
      border: 0;
      padding: 0 16px;
    }
  }
  &.secondary-danger {
    .modal-header {
      color: $danger-600;
      background-color: white;
      padding-top: 32px;
      padding-bottom: 4px;
    }
    .modal-body {
      border: 0;
      padding: 0 16px;
    }
  }
}
