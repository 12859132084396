@import '../../assets/scss/typography';

.archive-transition-container {
  .modal-no-files,
  .modal-more-files {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: 5rem 5rem;
    overflow: hidden;
    @include body-regular;
  }

  .watermark-large {
    left: calc(50vw - 1520px);
    bottom: -40%;
    position: absolute;
  }

  .watermark-small {
    left: calc(50vw + 200px);
    top: 5%;
    position: absolute;
  }

  .modal-transition-config {
    z-index: 2000;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0s 0.5s;
  }

  .modal-more-files {
    color: $white;
    background-color: $branding-sdc-900;
    @extend .modal-transition-config;
  }

  .modal-no-files {
    color: $grey-800;
    background-color: $white;
    @extend .modal-transition-config;
  }

  .modal-more-files.active,
  .modal-no-files.active {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s;
  }

  .icon {
    padding-bottom: 30px;
  }

  .primary-action {
    padding: 1.5rem 0 0.5rem 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .or {
    padding: 1rem 0;
  }

  .close-notes {
    text-align: center;
    padding-top: 7rem;
    white-space: pre-line;
  }

  // Adjust paddings for smaller screen based on screen height
  @media screen and (max-height: 900px) {
    .close-notes {
      text-align: center;
      padding-top: 16px;
    }

    .modal-no-files,
    .modal-more-files {
      padding: 1rem 1rem;
    }

    .or {
      padding: 8px 0;
    }

    .icon {
      padding-bottom: 16px;
    }
  }
}
