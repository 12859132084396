@import '/src/assets/scss/typography';

// Credit: Copied from the custom radio button tutorial
// https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
.radio-option {
  .container {
    display: block;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include body-regular;
    color: $grey-800;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 4px;
    left: 0px;
    height: 17px;
    width: 17px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid $grey-400;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: $grey-200;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: $branding-sdc-500;
    transition: background-color 0.4s ease-in;
    border: none;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 7px;
    left: 6.5px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: white;
  }
}
